import { useState } from "react";

const MONTHS: any = [
	"Januari",
	"Februari",
	"Mars",
	"April",
	"Maj",
	"Juni",
	"Juli",
	"Augusti",
	"September",
	"Oktober",
	"November",
	"December",
];

const generateMonths = (): string[] => {
	const CURRENT_DATE = new Date();
	const CURRENT_YEAR = CURRENT_DATE.getFullYear();
	const CURRENT_MONTH = CURRENT_DATE.getMonth();

	const months = [];

	for (let i = 0; i < 12; i++) {
		const month = new Date(CURRENT_YEAR, CURRENT_MONTH - i);
		const year = month.getFullYear();
		const monthNumber = month.getMonth();
		const monthName = MONTHS[monthNumber];

		months.push(`${year}${(monthNumber + 1).toString().padStart(2, "0")}`);

		if (year < 2020) {
			break;
		}
	}

	return months;
  };

export default function MonthSelector({
	value,
	onChange,
}: {
	value: string;
	onChange: any;
}) {
	const year = value.slice(0, 4);
	const month = value.slice(4, 6);
	const [open, setOpen] = useState(false);
	
	const months = generateMonths();

	return (
		<div className="relative select-none z-20">
			<span className="font-semibold text-blue-600 text-opacity-50 text-xs ml-1 px-1 absolute -top-4 dark:text-stone-200">
				Månad
			</span>
			<div
				onClick={() => setOpen(!open)}
				className="flex ml-2 px-6 py-2 bg-blue-100 dark:bg-blue-950 dark:text-blue-400 text-blue-600 font-medium rounded text-sm cursor-pointer hover:bg-blue-50 transition-colors ease-linear"
			>
				{MONTHS[parseInt(month) - 1]} {year}
			</div>
			{open && (
				<div className="absolute top-0 right-0 mt-8 bg-white dark:bg-stone-800 border border-stone-200 dark:border-stone-800 rounded shadow-lg">
					<div className="flex flex-col">
						{months.map((month) => (
							<div
								key={month}
								className="p-2 text-center cursor-pointer hover:bg-stone-100 dark:hover:bg-stone-700"
								onClick={() => {
									onChange(month);
									setOpen(false);
								}}
							>
								{MONTHS[parseInt(month.substring(4)) - 1] + " " + month.substring(2, 4)}
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
