const Button = (props: {
	text: string;
	onClick: () => void;
	color: string;
	disabled?: boolean;
}) => {
	const COLORS = {
		blue: {
			bg: "bg-blue-100 dark:bg-blue-950",
			text: "text-blue-600 dark:text-blue-400",
		},
		red: {
			bg: "bg-red-100",
			text: "text-red-600",
		},
		green: {
			bg: "bg-green-100",
			text: "text-green-600",
		},
		gray: {
			bg: "bg-gray-100 dark:bg-stone-900",
			text: "text-gray-60 dark:text-stone-500",
		},
		yellow: {
			bg: "bg-yellow-100",
			text: "text-yellow-600",
		},
	};

	let color = props.color ?? "blue";
	const disabled = props.disabled ?? false;

	if (disabled) {
		color = "gray";
	}

	return (
		<button
			className={
				"flex ml-2 px-4 py-2 font-medium rounded text-sm transition-opacity ease-in hover:opacity-80 " +
				COLORS[color]?.bg +
				" " +
				COLORS[color]?.text
			}
			onClick={props.onClick}
			disabled={disabled}
		>
			{props.text}
		</button>
	);
};

export default Button;
