import TableHeader from "../../components/tableHeader";
import { prettyDateTime } from "../../helper/date";
import useFetch from "../../helper/swr";
import { useState } from "react";

const HEADER_NAMES = [
	{
		name: "Skapad",
		width: "w-48",
	},
	{
		name: "Skickad",
		width: "w-48",
	},
	{
		name: "Antal turer",
		width: "w-48",
	},
	{
		name: "ID",
		width: "flex-1",
	},
	{
		name: "Status",
		width: "w-36",
	},
];

type TripType = {
	created: string;
	sent: string;
	id: string;
	trips: string;
};

function TripRow({ trip: { created, sent, id, trips } }: { trip: TripType }) {
	const [showTrips, setShowTrips] = useState(false);

	return (
		<div
			onClick={() => setShowTrips(!showTrips)}
			className="flex flex-col w-full items-center cursor-pointer"
		>
			<div className="flex flex-row w-full items-center px-4 py-2 border-b hover:bg-stone-50 ease-out transition-all">
				<p className="font-semibold text-sm w-48">{prettyDateTime(created)}</p>
				<p className="font-semibold text-sm w-48 ">
					{created ? prettyDateTime(created) : "Ej skickad"}
				</p>
				<p className="font-semibold text-sm w-48">{JSON.parse(trips ?? "[]")?.length}st</p>
				<p className="font-semibold text-sm">{id}</p>
				<div className="w-36 ml-auto flex">
					<p className="font-semibold text-xs bg-green-200 text-green-700 px-2 rounded py-0.5">
						{!created ? "Skapad" : "Skickad"}
					</p>
				</div>
			</div>
			{showTrips ? (
				<div className="flex flex-col w-full bg-gray-100">
					<div className="flex flex-row w-full items-center px-4 py-2 border-b">
						<p className="font-semibold text-sm w-48">Fordon</p>
						<p className="font-semibold text-sm w-48">Dag</p>
						<p className="font-semibold text-sm w-48">Linje</p>
						<p className="font-semibold text-sm w-48">Tur</p>
					</div>
					{JSON.parse(trips ?? "[]")?.map((tripString: any) => {
						const [vehicle, day, line, trip] = tripString.split("-");
						return (
							<div
								key={"trip-" + tripString}
								className="flex flex-row w-full items-center px-4 py-2 border-b"
							>
								<p className="text-sm w-48">{vehicle}</p>
								<p className="text-sm w-48">{day}</p>
								<p className="text-sm w-48">{line}</p>
								<p className="text-sm w-48">{trip}</p>
							</div>
						);
					})}
				</div>
			) : null}
		</div>
	);
}

const Trips = ({
	trips,
	isLoading,
	isError,
}: {
	trips: TripType[] | undefined;
	isLoading: boolean;
	isError: boolean;
}): any => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	if (trips === undefined || trips?.length === 0) {
		return <p className="m-auto text-stone-500">Inga eftersändningar hittades</p>;
	}

	return trips
		.sort((a, b) => (new Date(a.created).getTime() < new Date(b.created).getTime() ? 1 : -1))
		.map((trip, i) => <TripRow key={"dr-" + i} trip={trip} />);
};

export default function Resent() {
	const { data: trips, isLoading, isError } = useFetch("/resenttrips");

	return (
		<div className="flex flex-1 px-6 flex-col">
			<div className="flex flex-row items-center justify-between mb-8 mt-12">
				<div>
					<h1 className="font-semibold text-2xl">Eftersändningar</h1>
					<p className="font-medium text-stone text-sm w-40 text-stone-500 whitespace-nowrap">
						Alla skickade eftersändningar
					</p>
				</div>
			</div>
			<div className="flex flex-1 flex-col w-full">
				<TableHeader items={HEADER_NAMES} />
				<Trips trips={trips} isLoading={isLoading} isError={isError} />
			</div>
		</div>
	);
}
