import ContractSelector from "../../components/contractSelector";
import Input from "../../components/input";
import InputSelect from "../../components/inputSelect";
import Modal from "../../components/modal";
import MonthSelector from "../../components/monthSelector";
import TableHeader from "../../components/tableHeader";
import TRIP_TRAFFIC_AREA_OWBER from "../../data/lines";
import { getTodayDateDashed, getYearMonthFormat } from "../../helper/date";
import { zeroed } from "../../helper/misc";
import postman from "../../helper/postman";

import useFetch from "../../helper/swr";
import usePersistentState from "../../helper/usePersistentState";
import { createXLSXFile } from "../../helper/xlsx";
import { useState } from "react";
import toast from "react-hot-toast";
import { utils } from "xlsx";

const HEADER_NAMES = [
	{ name: "Typ", width: "w-12" },
	{ name: "Datum", width: "w-28" },
	{ name: "Linje", width: "w-20" },
	{ name: "Tur", width: "w-20" },
	{ name: "Orsak", width: "w-24" },
	{ name: "Beskrivning", width: "w-36" },
	{ name: "Start hpl", width: "w-36" },
	{ name: "Slut hpl", width: "w-36" },
	{ name: "Inst hpl", width: "w-36" },
	{ name: "RT", width: "w-36" },
];

const TYPE_MAP = {
	h19: "H19",
	partiallyCancelled: "DEL",
	cancelled: "HEL",
	falseH19: "Falsk-H19",
};

export function TripRow({
	trip,
	deleteTrip,
}: {
	trip: {
		operatingDay: string;
		line: string;
		trip: string;
		type: string;
		reason: string;
		description: string;
		fromStop: string;
		toStop: string;
		disruptionNumber: string;
		correctedStop: string;
	};
	deleteTrip: (trip: any) => void;
}) {
	return (
		<div className="flex flex-row w-full items-center px-4 py-2 border-b ease-out transition-all">
			<p className="font-semibold text-sm w-12">{TYPE_MAP[trip.type]}</p>
			<p className="font-semibold text-sm w-28">{trip.operatingDay}</p>
			<div className="flex w-20">
				<p className={"font-semibold text-sm px-1.5 rounded bg-slate-200"}>{trip.line}</p>
			</div>
			<p className="font-semibold text-sm w-20">{trip.trip}</p>
			<p className="font-semibold text-sm w-24">{trip.reason}</p>
			<p className="font-semibold text-sm w-36 pr-3 overflow-hidden text-ellipsis">
				{trip.description}
			</p>
			<p className="font-semibold text-sm w-36">{trip.fromStop}</p>
			<p className="font-semibold text-sm w-36">{trip.toStop}</p>
			<p className="font-semibold text-sm w-36">{trip.correctedStop}</p>
			<p className="font-semibold text-sm w-36">{trip.disruptionNumber}</p>
			{/* <div
				className="flex items-center justify-center px-2 py-0.5 font-medium text-xs bg-red-100 text-red-500 rounded cursor-pointer hover:bg-red-200"
				onClick={() => {
					deleteTrip(trip);
				}}
			>
				Ta bort
			</div> */}
		</div>
	);
}

const Trips = ({
	trips,
	isLoading,
	isError,
	deleteTrip,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
	deleteTrip: (trip: any) => void;
}) => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (trips === undefined || trips?.length === 0) {
		return <p className="m-auto text-stone-500">Inga turer hittades</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	return (
		<div className="flex flex-1 w-full flex-col">
			{trips.map((trip, i) => (
				<TripRow key={"dr-" + i} trip={trip} deleteTrip={deleteTrip} />
			))}
		</div>
	);
};

export default function FinedTrips() {
	const [month, setMonth] = usePersistentState("vassdupp-month", getYearMonthFormat());
	const [contract, setContract] = useState("ALL");
	const [page] = useState(1);
	const {
		data: trips,
		isLoading: isLoadingTrips,
		isError: isTripError,
		mutate,
	} = useFetch(`finedtrips/${contract}/${month}`);

	const [showModal, setModal] = useState(false);

	const handleCancel = () => {
		resetForm();
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const data = {
			type,
			operatingDay,
			line,
			trip,
			reason,
			description,
			compass,
		};

		const newTrips = trips ? [...trips] : [];

		newTrips.push(data);

		mutate(newTrips, false);

		toast.promise(postman.post(`finedtrips`, data), {
			loading: "Lägger till tur...",

			success: "Turen har lagts till",
			error: "Ett fel uppstod",
		});

		setModal(false);
	};

	const resetForm = () => {
		setModal(false);
	};

	const deleteTrip = (trip) => {
		// Filter on opeartingDay, line and trip
		const newTrips = trips.filter(
			(t) =>
				t.operatingDay !== trip.operatingDay || t.line !== trip.line || t.trip !== trip.trip
		);

		mutate(newTrips, false);

		toast.promise(postman.delete(`finedtrips/${trip.operatingDay}/${trip.line}/${trip.trip}`), {
			loading: "Tar bort tur...",
			success: "Turen har tagits bort",
			error: "Ett fel uppstod",
		});
	};

	const [operatingDay, setOperatingDay] = useState(getTodayDateDashed());
	const [line, setLine] = useState("");
	const [trip, setTrip] = useState("");
	const [reason, setReason] = useState("Yttre");
	const [description, setReasonDescription] = useState("");
	const [compass, setCompass] = useState("");
	const [type, setType] = useState("partiallyCancelled");

	const caluclateSetReasonDescription = (reason) => {
		setReasonDescription(reason);

		const lowerReason = reason.toLowerCase();

		// teknik
		if (lowerReason.includes("dörr")) {
			setReason("Teknik");
			return;
		}

		if (lowerReason.includes("motor")) {
			setReason("Teknik");
			return;
		}

		if (lowerReason.includes("vagn")) {
			setReason("Teknik");
			return;
		}

		if (lowerReason.includes("däck")) {
			setReason("Teknik");
			return;
		}

		if (lowerReason.includes("broms")) {
			setReason("Teknik");
			return;
		}

		if (lowerReason.includes("start")) {
			setReason("Teknik");
			return;
		}

		if (lowerReason.includes("lås")) {
			setReason("Teknik");
			return;
		}

		if (lowerReason.includes("läckage")) {
			setReason("Teknik");
			return;
		}

		// Yttre
		if (lowerReason.includes("trafik")) {
			setReason("Yttre");
			return;
		}

		if (lowerReason.includes("köer")) {
			setReason("Yttre");
			return;
		}

		if (lowerReason.includes("olycka")) {
			setReason("Yttre");
			return;
		}

		if (lowerReason.includes("ordning")) {
			setReason("Yttre");
			return;
		}

		if (lowerReason.includes("färja")) {
			setReason("Yttre");
			return;
		}

		if (lowerReason.includes("övergång")) {
			setReason("Yttre");
			return;
		}

		if (lowerReason.includes("framkomlig")) {
			setReason("Yttre");
			return;
		}

		if (lowerReason.includes("avstängd")) {
			setReason("Yttre");
			return;
		}

		if (lowerReason.includes("väg")) {
			setReason("Yttre");
			return;
		}

		// Personal
		if (lowerReason.includes("felkörning")) {
			setReason("Personal");
			return;
		}

		if (lowerReason.includes("Försovning")) {
			setReason("Personal");
			return;
		}

		if (lowerReason.includes("personal")) {
			setReason("Personal");
			return;
		}
	};

	return (
		<div className="flex flex-1 px-6 flex-col">
			<Modal show={showModal} closeModal={() => setModal(false)}>
				<form
					className="flex flex-col justify-between w-full min-w-96 h-full items-center py-12 px-32"
					onSubmit={handleSubmit}
				>
					<div className="flex flex-col items-center mb-6">
						<h1 className="font-medium text-xl">Lägg till vitesbelagd tur</h1>
						<p className="mt-2 text-slate-500 text-sm">Lägg till en vitesbelagd tur</p>
					</div>

					<div className="relative select-none w-full">
						<InputSelect
							placeholder="Typ"
							value={type}
							onChange={setType}
							items={[
								["h19", "H19"],
								["partiallyCancelled", "Delvis inställd"],
								["cancelled", "Inställd"],
								["falseH19", "Falsk-H19"],
							]}
						/>
					</div>

					<div className="mt-6 w-full">
						<Input
							placeholder="Trafikdygn"
							type="date"
							value={operatingDay}
							onChange={setOperatingDay}
						/>
					</div>

					<div className="mt-6 w-full">
						<Input placeholder="Linje" value={line} onChange={setLine} />
					</div>

					<div className="mt-6 w-full">
						<Input placeholder="Tur" value={trip} onChange={setTrip} />
					</div>

					<div className="mt-6 w-full">
						<Input
							placeholder="Orsak beskrivning"
							value={description}
							onChange={caluclateSetReasonDescription}
						/>
					</div>

					<div className="relative mt-6 select-none w-full">
						<InputSelect
							placeholder="Orsak"
							value={reason}
							onChange={setReason}
							items={[
								["Personal", "Personal"],
								["Teknik", "Teknik"],
								["Yttre", "Yttre"],
							]}
						/>
					</div>

					<div className="mt-6 w-full">
						<Input placeholder="Compass" value={compass} onChange={setCompass} />
					</div>

					<div className="flex flex-row mt-6 justify-center items-center">
						<div
							className="bg-slate-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:text-black"
							onClick={() => {
								handleCancel();
							}}
						>
							Avbryt
						</div>
						<input
							type="submit"
							className="bg-blue-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 ml-6 dark:text-black dark:bg-blue-400"
							value="Lägg till"
						/>
					</div>
				</form>
			</Modal>
			<div className="flex w-full justify-between items-center mb-6 flex-col">
				<div className="flex w-full justify-between items-center">
					<div>
						<h1 className="mt-12 font-semibold text-2xl">Vitesbelagda turer</h1>
						{trips ? (
							<h1 className="font-semibold text-sm text-gray-700">
								{trips.length}st turer under period
							</h1>
						) : null}
						<p className="font-medium text-stone text-sm w-40 text-stone-500 mb-8 whitespace-nowrap">
							Alla turer som är vitesbelagda i trafiken för en specifierad period
						</p>
					</div>
					<div className="flex">
						<MonthSelector value={month} onChange={setMonth} />
						<ContractSelector value={contract} onChange={setContract} />
					</div>
				</div>
				<div className="flex items-end justify-end w-full">
					<div
						onClick={() => setModal(true)}
						className="flex px-6 py-2 bg-blue-100 text-blue-600 dark:bg-blue-950 dark:text-blue-400 ml-auto font-medium rounded text-sm cursor-pointer hover:bg-blue-50 transition-colors ease-linear"
					>
						Lägg till vitesbelagd tur
					</div>
					{/* <div
						onClick={() => createExport()}
						className="flex px-6 py-2 bg-blue-100 text-blue-600 ml-auto font-medium rounded text-sm cursor-pointer hover:bg-blue-50 transition-colors ease-linear"
					>
						Export
					</div> */}
				</div>
			</div>

			<div className="flex flex-1 flex-row w-full">
				<div className="flex flex-1 flex-col w-full ml-2">
					<h1 className="font-semibold text-sm text-gray-700">Vitesbelagda</h1>
					<TableHeader items={HEADER_NAMES} />
					<Trips
						trips={trips}
						isLoading={isLoadingTrips}
						isError={isTripError}
						deleteTrip={deleteTrip}
					/>
				</div>
			</div>
		</div>
	);
}
