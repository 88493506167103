import { navigate } from "wouter/use-browser-location";
import { safeUserStorage } from "./storage";

export const checkIfUserIsLoggedIn = () => {
    const USER: any = safeUserStorage();
    
            if (!USER) {
                navigate("/");
            }
    
            const PARSED_USER = JSON.parse(USER);
    
            if (new Date().getTime() > PARSED_USER.expires) {
                localStorage.removeItem("user");
                navigate("/", { replace: true, state: { expired: true } });
            }
}