import * as XLSX from "xlsx";

import TrainGraph from "../components/trainGraph";
import { useState } from "react";
import View from "../components/view";
import InputFile from "../components/inputFile";

const STATIONS: any = {
	L27: [
		{ name: "Kår", distance: 41.7 },
		{ name: "Fsu", distance: 34.6 },
		{ name: "Ldm", distance: 28.8 },
		{ name: "Vdl", distance: 26.8 },
		{ name: "Ota", distance: 24.1 },
		{ name: "Vlt", distance: 22.8 },
		{ name: "Rnb", distance: 11 },
		{ name: "Djö", distance: 6.7 },
		{ name: "Öst", distance: 0 },
	],
	L28: [
		{ name: "Ösk", distance: 29.5 },
		{ name: "Åbg", distance: 27.2 },
		{ name: "Rdb", distance: 20 },
		{ name: "Are", distance: 17.2 },
		{ name: "Hgä", distance: 15.6 },
		{ name: "Vbm", distance: 14.3 },
		{ name: "Rnb", distance: 11.0 },
	],
	L29: [
		{ name: "Nbp", distance: 11.7 },
		{ name: "Alp", distance: 8.5 },
		{ name: "Djö", distance: 6.7 },
	],
};

const getTripTimeDimensions = (trafficData: any) => {
	let minTime = Infinity;
	let maxTime = -Infinity;

	for (const line in trafficData) {
		const lineData = trafficData[line];

		for (let i = 0; i < lineData.length; i++) {
			const trip = lineData[i];

			minTime = Math.min(minTime, trip.startTime, trip.endTime);
			maxTime = Math.max(maxTime, trip.endTime, trip.startTime);
		}
	}

	return { minTime, maxTime };
};

const flattenTrips = (trips: any) => {
	const res: any[] = [];

	for (let i = 0; i < trips.length; i++) {
		const { stations, ...trip } = trips[i];

		for (let j = 0; j < stations.length; j++) {
			res.push({ ...trip, ...stations[j] });
		}
	}

	return res;
};

const convertTimeStringToMinutes = (time: string) => {
	const [hours, minutes] = time.split(":");
	return parseInt(hours) * 60 + parseInt(minutes);
};

const convertToNoobStationsFormat = (
	old_stations: any[],
	periodTime: string,
	mallTrain: string
) => {
	const line_specific: any = [];
	const common: any = [];

	for (let i = 0; i < old_stations.length; i++) {
		const station = old_stations[i];
		const nextStation = old_stations[i + 1];

		if (nextStation) {
			const startStation = station.name;
			const endStation = nextStation.name;
			const startTime = convertTimeStringToMinutes(station.time);
			const endTime = convertTimeStringToMinutes(nextStation.time);

			if (
				(startStation === "Öst" && endStation === "Djö") ||
				(startStation === "Djö" && endStation === "Öst") ||
				(startStation === "Djö" && endStation === "Rnb") ||
				(startStation === "Rnb" && endStation === "Djö")
			) {
				common.push({
					startStation,
					endStation,
					startTime,
					endTime,
					periodTime,
					mallTrain,
				});
			} else {
				line_specific.push({ startStation, endStation, startTime, endTime });
			}
		}
	}

	return [line_specific, common];
};

const createTrainTripsFromWSJON = (
	rows: string[][],
	reverse_station_order = false,
	headers: string[]
) => {
	const trips: any = [];
	const all_common_stations: any = [];

	console.log(headers);

	const variantIndex = headers.indexOf("Trafikupplägg");
	const periodIndex = headers.indexOf("Period");
	const lineIndex = headers.indexOf("Linje");
	const trainNumberIndex = headers.indexOf("Tågnr");
	const periodTimeIndex = headers.indexOf("Periodtid");
	const mallTrainIndex = headers.indexOf("Malltåg");
	const lengthIndex = headers.indexOf("Längd");
	const firstStationIndex = lengthIndex + 1;

	for (let i = 0; i < rows.length; i++) {
		const row = rows[i];

		// If empty row, remove.
		if (row[0] === "") {
			break;
		}

		const row_stations = row.slice(firstStationIndex);

		const stations: any = [];

		for (let j = 0; j < row_stations.length; j++) {
			const station = row_stations[j];

			// Check if station is anything but timestamp, if so it is irrelevant.
			if (station.split(":").length !== 2) {
				continue;
			}

			stations.push({
				name: headers[firstStationIndex + j],
				time: station,
			});
		}

		const [line_specific_stations, common_stations] = convertToNoobStationsFormat(
			reverse_station_order ? stations.reverse() : stations,
			row[periodTimeIndex],
			row[mallTrainIndex]
		);

		const trip = {
			variant: row[variantIndex],
			period: row[periodIndex],
			trainNumber: row[trainNumberIndex],
			length: row[lengthIndex],
			line: row[lineIndex],
			stations: line_specific_stations,
			periodTime: row[periodTimeIndex],
			mallTrain: row[mallTrainIndex],
		};

		trips.push(trip);
		all_common_stations.push(...common_stations);
	}

	return [trips, all_common_stations];
};

export default function CreateTimeDistanceGraph() {
	const [trafficData, setTrafficData] = useState<any>(null);
	const [view, setView] = useState<"file" | "graph">("file");

	const handleTrafficData = (file: any) => {
		const reader = new FileReader();
		reader.onload = (event: any) => {
			// Parse data
			const bstr = event.target.result;

			const wb: any = XLSX.read(bstr, { type: "binary" });

			const wsname = wb.SheetNames[1];
			const ws = wb.Sheets[wsname];

			const sheet_csv = XLSX.utils.sheet_to_csv(ws, {
				strip: true,
				RS: "§",
				blankrows: false,
			});

			const cleaned_csv = sheet_csv
				.replaceAll("\n", "")
				.replaceAll("\r", "")
				.replaceAll("§", "\n");

			const rows = cleaned_csv.split("\n");

			let headerIndex = 0;

			for (let i = 0; i < rows.length; i++) {
				const row = rows[i];

				if (row.includes("Malltåg")) {
					headerIndex = i;
					break;
				}
			}

			const secoundSplit = rows[0].split(",").findIndex((item) => item === "Nedgående");
			const full_header = rows[headerIndex].split(",");
			const header_up = full_header.slice(0, secoundSplit);
			const header_down = full_header.slice(secoundSplit);
			const unf_rows_up: any = [];
			const unf_rows_down: any = [];

			for (let i = headerIndex; i < rows.length; i++) {
				const columns = rows[i].split(",");

				// Skip first row.
				if (i === headerIndex) {
					continue;
				}

				if (columns[0] === "") {
					break;
				}

				unf_rows_up.push(columns.slice(0, secoundSplit));
				unf_rows_down.push(columns.slice(secoundSplit));
			}

			const [line_specific_stations_upp, common_stations_up] = createTrainTripsFromWSJON(
				unf_rows_up,
				false,
				header_up
			);

			const [line_specific_stations_down, common_stations_down] = createTrainTripsFromWSJON(
				unf_rows_down,
				true,
				header_down
			);

			const trips = flattenTrips([
				...line_specific_stations_upp,
				...line_specific_stations_down,
			]);
			const common_trips = [...common_stations_up, ...common_stations_down];

			const lines: any = {
				L29: [],
				L28: [],
				L27: [...common_trips],
			};

			for (let i = 0; i < trips.length; i++) {
				const trip = trips[i];
				lines["L" + trip.line].push(trip);
			}

			setTrafficData(lines);
			setView("graph");
		};
		reader.readAsBinaryString(file);
	};

	const { minTime: xStart, maxTime: xEnd } = getTripTimeDimensions(trafficData);

	return (
		<div className="flex flex-1 pt-2 w-full flex-col">
			<h1 className="text-2xl font-bold mb-4 mt-10">Roslagsbanan ST-graf generator</h1>

			<View show={view === "file"}>
				<p className="mt-4 text-sm font-medium">Ladda upp excel-fil</p>
				<InputFile accept=".xlsx" name="asdasd" onHandleFile={handleTrafficData} />
			</View>

			<View show={view === "graph"}>
				{trafficData
					? Object.entries(trafficData).map(([line, trips]: any) => {
							return (
								<div key={line} className="flex flex-col">
									<h2 className="text-xl font-bold -mb-2">{line}</h2>
									<TrainGraph
										trips={trips}
										stations={STATIONS[line]}
										dimensions={{ xEnd, xStart, yEnd: 0, yStart: 0 }}
									/>
								</div>
							);
					  })
					: null}
			</View>
		</div>
	);
}
