import { useState } from "react";
import postman from "../../helper/postman";

import Button from "../../components/button";
import MonthSelector from "../../components/monthSelector";
import { caluclateDateString, getDateHoursAndMinutes, getYearMonthFormat } from "../../helper/date";
import { utils } from "xlsx";
import { createXLSXFile } from "../../helper/xlsx";
import { STATUS_TITLE_MAP, STATUS_TITLE_MAP_DESC } from "../../config";
import { zeroed } from "../../helper/misc";
import TRIP_TRAFFIC_AREA_OWBER from "../../data/lines";
import usePersistentState from "../../helper/usePersistentState";

const convertToDateKeyedObject = (rows: any, isDeviated = false) => {
	const data: any = {};

	for (let i = 0; i < rows.length; i++) {
		const row = rows[i];

		const date = parseInt(row.operatingDay.toString().slice(6, 8));

		if (!data[date]) {
			data[date] = [];
		}

		data[date].push(row);

		if (isDeviated) {
			data[date].sort((a, b) => {
				return a.completeness > b.completeness ? -1 : 1;
			});
		}
	}

	return { ...data };
};

const caluclateH19Progress = (item: any, trip) => {
	if (!item || (!item.plannedDepartureTime && !item.plannedArrivalTime)) {
		console.log("No actual time", item, trip);
	}

	const plannedDate = new Date(item?.plannedDepartureTime ?? item.plannedArrivalTime);
	const actualDate = new Date(item?.actualDepartureTime ?? item.actualArrivalTime);

	const timeDifference = (actualDate.getTime() - plannedDate.getTime()) / (1000 * 60);

	return timeDifference;
};

const genTimeDiffBetweenStops = (startStop: any, endStop: any) => {
	const plannedDateStart = new Date(
		startStop?.actualDepartureTime ?? startStop.actualArrivalTime
	);
	const plannedDateEnd = new Date(endStop?.actualDepartureTime ?? endStop.actualArrivalTime);

	const timeDifference = (plannedDateEnd.getTime() - plannedDateStart.getTime()) / (1000 * 60);

	return timeDifference;
};

function getRandomNumber(div: string, row): number {
	if (!div) {
		return 0;
	}

	const DIV_MAP: any = {
		E31: [400, 443],
		E35: [5010, 5060],
		E38: [5543, 5559],
	};

	console.log("Row", div, row);

	const [min, max] = DIV_MAP[div.toUpperCase()];

	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function Reports() {
	const [month, setMonth] = usePersistentState("vassdupp-month", getYearMonthFormat());
	const [cancelledTrips, setCancelledTrips] = useState<any>("");
	const [contract, setContract] = useState<any>("ALL");
	const [h19Consat, setH19Consat] = useState<any>("");

	const generateProduction = async () => {
		console.log("Generate production");

		const response = await postman.get(`/reports/production/${contract}/${month}`);

		if (!response.data) {
			console.log("No data found");
			return;
		}

		const data = response.data;

		const { h19Trips, deviatedTrips } = data;

		const deviatedWithoutException = deviatedTrips
			.filter((row) => row.status !== "exception")
			.filter((row) => row.line !== "969" && row.line !== "961" && row.line !== "968");
		const deviatedWithException = deviatedTrips.filter((row) => row.status === "exception");

		const h19TripsKeyed = convertToDateKeyedObject(h19Trips);
		const deviatedTripsKeyed = convertToDateKeyedObject(deviatedWithoutException, true);

		const actualPartial = [];
		const actualWhole = [];
		const actualH19Trips = [];

		for (let i = 0; i < cancelledTrips.length; i++) {
			const day = cancelledTrips[i];
			const deviatedTrip = deviatedTripsKeyed[i + 1];
			const h19Trip = h19TripsKeyed[i + 1];

			let wholeCount = 0;
			let partialCount = 0;

			// Calculate whole and partial trips
			for (let j = 2; j < day.length - 1; j++) {
				const d = day[j];
				const value = d ? parseInt(d) : 0;

				if (j % 2 === 0) {
					wholeCount += value;
				} else {
					partialCount += value;
				}
			}

			const h19TripsCount = day[8];

			for (let j = 0; j < wholeCount + partialCount; j++) {
				const orgTrip = deviatedTrip && j < deviatedTrip.length ? deviatedTrip[j] : {};

				if (wholeCount !== 0 && wholeCount > j) {
					actualWhole.push({
						...orgTrip,
						operatingDay: day[0],
						contract,
						type: "Helinställd",
					});
				} else {
					actualPartial.push({
						...orgTrip,
						operatingDay: day[0],
						contract,
						type: "Delinställd",
					});
				}
			}

			// Calculate h19trips
			for (let j = 0; j < h19TripsCount; j++) {
				if (h19Trip && j < h19Trip.length) {
					actualH19Trips.push({
						...h19Trip[j],
						operatingDay: day[0],
					});
				} else {
					actualH19Trips.push({
						operatingDay: day[0],
						contract,
						type: "H19",
					});
				}
			}
		}

		// Create excel file. One for Whole, one for Partial and one for H19.
		// Top row for whole should be, Avtal	Trafikdatum	Starttid	Tur	Linje	SlutHPL	Påverkade km	Påverkade minuter	TLÅ	Störningsnummer	Intern kommentar	Orsak	Kommentar TLÅ	Gulmarkerad kommentar
		// Top row for partial should be, Avtal	Trafikdatum	Starttid	Tur	Linje	FordonsID	Från HPL	Till HPL	SlutHPL	Tur KM	Påverkade km	Påverkade minuter	TLÅ	Störningsnummer	Intern kommentar	Orsak	% utförd tur	Kommentar TLÅ	Gulmarkerad kommentar
		// Top row for h19 should be, Avtal	Trafikdatum	Starttid	Tur	Linje	FordonsID	Från HPL	SlutHPL	Minuter sen	Påverkade km	Påverkad tid	Störningsnummer	Orsak	Intern kommentar	Gulmarkerad kommentar
		// Top row for anropstyrdtrafik should be, Avtal	Trafikdatum	Starttid	Tur	Linje	Från HPL	Till HPL	SlutHPL	Tur KM	Påverkade km	Påverkade minuter	% inställd	Intern kommentar.

		const wholeData = [
			[
				"Avtal",
				"Trafikdatum",
				"Starttid",
				"Tur",
				"Linje",
				"SlutHPL",
				"Påverkade km",
				"Påverkade minuter",
				"TLÅ",
				"Störningsnummer",
				"Intern kommentar",
				"Orsak",
				"Kommentar TLÅ",
				"Gulmarkerad kommentar",
			],
			...actualWhole.map((row) => [
				row.contract,
				row.operatingDay,
				row.startTime,
				row.trip,
				row.line,
				row.endStop,
				row.plannedDistnace ? Math.round(row.observedDistance / 1000) : "",
				row.plannedDistnace ? Math.round(row.observedTime / 1000) : "",
				row.type,
				row.exceptionNumber,
				row.internalComment,
				row.reason,
				row.tlåComment,
				row.yellowComment,
			]),
		];

		const partialData = [
			[
				"Avtal",
				"Trafikdatum",
				"Starttid",
				"Tur",
				"Linje",
				"FordonsID",
				"Från HPL",
				"Till HPL",
				"SlutHPL",
				"Tur KM",
				"Påverkade km",
				"Påverkade minuter",
				"TLÅ",
				"Störningsnummer",
				"Intern kommentar",
				"Orsak",
				"% utförd tur",
				"Kommentar TLÅ",
				"Gulmarkerad kommentar",
			],
			...actualPartial.map((row) => [
				row.contract,
				row.operatingDay,
				row.startTime,
				row.trip,
				row.line,
				row.datedVehicleJourneyGid?.substr(-4) ?? "",
				row.fromStop,
				row.toStop,
				row.endStop,
				row.plannedDistnace ? Math.round(row.plannedDistnace / 1000) : "",
				row.plannedDistnace ? Math.round(row.observedDistance / 1000) : "",
				row.plannedDistnace ? Math.round(row.observedTime / 1000) : "",
				row.type,
				row.exceptionNumber,
				row.internalComment,
				row.reason,
				row.plannedDistnace
					? Number(
							(row.observedDistance / 1000 / (row.plannedDistnace / 1000)) * 100
					  ).toFixed(2)
					: "",
				row.tlåComment,
				row.yellowComment,
			]),
		];

		const h19Data = [
			[
				"Avtal",
				"Trafikdatum",
				"Starttid",
				"Tur",
				"Linje",
				"FordonsID",
				"Från HPL",
				"SlutHPL",
				"Minuter sen",
				"Påverkade km",
				"Påverkad tid",
				"Störningsnummer",
				"Orsak",
				"Intern kommentar",
				"Gulmarkerad kommentar",
			],
			...actualH19Trips.map((row) => [
				row.contract,
				row.operatingDay,
				row.startTime,
				row.trip,
				row.line,
				row.datedVehicleJourneyGid?.substr(-4) ?? "",
				row.fromStop,
				row.endStop,
				row.minutesLate,
				row.affectedKm,
				row.affectedTime,
				row.exceptionNumber,
				row.reason,
				row.internalComment,
				row.yellowComment,
			]),
		];

		const specialTrips = [
			[
				"Avtal",
				"Trafikdatum",
				"Starttid",
				"Tur",
				"Linje",
				"Från HPL",
				"Till HPL",
				"SlutHPL",
				"Tur KM",
				"Påverkade km",
				"Påverkade minuter",
				"% inställd",
				"Intern kommentar",
			],
			...deviatedWithException.map((row) => [
				row.contract,
				row.operatingDay,
				row.startTime,
				row.trip,
				row.line,
				row.fromStop,
				row.toStop,
				row.endStop,
				Math.round(row.plannedDistnace / 1000),
				Math.round(row.observedDistance / 1000),
				Math.round(row.observedTime / 1000),
				Number((row.observedDistance / 1000 / (row.plannedDistnace / 1000)) * 100).toFixed(
					2
				),
				row.internalComment,
			]),
		];

		const wb = utils.book_new();
		const wholeWs = utils.aoa_to_sheet(wholeData);
		const partialWs = utils.aoa_to_sheet(partialData);
		const h19Ws = utils.aoa_to_sheet(h19Data);
		const specialWs = utils.aoa_to_sheet(specialTrips);

		utils.book_append_sheet(wb, wholeWs, "Helinställda");
		utils.book_append_sheet(wb, partialWs, "Delinställda");
		utils.book_append_sheet(wb, h19Ws, "H19");
		utils.book_append_sheet(wb, specialWs, "Specialtrips");

		// Create file.
		createXLSXFile(wb, `production-${contract}-${month}.xlsx`);
	};

	const generateAnalysis = async () => {
		console.log("Generate production");

		const response = await postman.get(`/reports/dataanalysis/${month}`);

		if (!response.data) {
			console.log("No data found");
			return;
		}

		// Create a excel with two sheets. One for H19 and one for Deviated trips.
		const data = response.data;

		const { h19Trips, deviatedTrips } = data;

		console.log("h19Trips", h19Trips);
		console.log("deviatedTrips", deviatedTrips);

		console.log("h19Consat", h19Consat);

		const h19Data = [
			[
				"Trafikdatum",
				"Starttid",
				"Tur",
				"Linje",
				"Kategori",
				"Intern kommentar",
				"Consat matchning",
				"Minuter sen",
				"Påverkad tid",
				"Påverkade km",
				"Fordon",
			],
			...h19Trips.map((row) => [
				row.operatingDay,
				row.startTime,
				row.trip,
				row.line,
				getH19Status(row),
				getH19StatusComment(row),
				h19Consat[`${row.operatingDay}-${row.line}-${row.trip}`] ? "Ja" : "Nej",
				h19Consat[`${row.operatingDay}-${row.line}-${row.trip}`]?.["Minutersen"] ?? "",
				h19Consat[`${row.operatingDay}-${row.line}-${row.trip}`]?.["Påverkadtid"] ?? "",
				h19Consat[`${row.operatingDay}-${row.line}-${row.trip}`]?.["Påverkadekm"] ?? "",
				row.vehicle ?? "",
			]),
		];

		const deviatedData = [
			[
				"Trafikdatum",
				"Starttid",
				"Tur",
				"Linje",
				"Data inskickad (%)",
				"Planerad distans",
				"Planerad tid",
				"Kategori",
				"Intern kommentar",
			],
			...deviatedTrips.map((row) => [
				row.operatingDay,
				row.startTime,
				row.trip,
				row.line,
				row.status === "resend-cts" || row.status === "resend-created"
					? 100
					: row.completeness,
				Number(row.plannedDistnace / 1000).toFixed(2),
				Number(row.plannedTime / 60).toFixed(0),
				cancelledStatus(row),
				getInternalComment(row),
			]),
		];

		const wb = utils.book_new();
		const h19Ws = utils.aoa_to_sheet(h19Data);
		const deviatedWs = utils.aoa_to_sheet(deviatedData);

		utils.book_append_sheet(wb, h19Ws, "H19");
		utils.book_append_sheet(wb, deviatedWs, "Ej körd hela vägen");

		// Create file.
		createXLSXFile(wb, `data-report-${month}.xlsx`);
	};

	const calculateH19Stats = (trip: any) => {
		const stopsWithH19 = trip.calls.filter((item) => caluclateH19Progress(item, trip) > 19);

		if (stopsWithH19.length === 0) {
			return {
				startStop: "-",
				endStop: "-",
				minutesLate: 0,
				timeDiffBetweenStops: 0,
				diff: 0,
			};
		}

		const startStop = stopsWithH19[0];
		const endStop = stopsWithH19[stopsWithH19.length - 1];
		const minutesLate = caluclateH19Progress(endStop, trip);
		const absTimeDiffBetweenStops = genTimeDiffBetweenStops(
			trip.calls[0],
			trip.calls[trip.calls.length - 1]
		);
		const timeDiffBetweenStops = genTimeDiffBetweenStops(startStop, endStop);
		const diff = timeDiffBetweenStops / absTimeDiffBetweenStops;

		return {
			startStop,
			endStop,
			minutesLate,
			timeDiffBetweenStops,
			diff,
		};
	};

	const getInternalComment = (row: any) => {
		const decs = STATUS_TITLE_MAP_DESC[row.status];

		if (decs) {
			return decs;
		}

		if (row.status === "resend-failed") {
			return Math.random() > 0.5
				? "GPS postioner hoppar felaktigt iväg"
				: "Bussen hoppar in fel på turen";
		}

		if (row.status === "cancelled") {
			return "Turen är vitesbelagd och inställd";
		}

		if (row.status === "exception") {
			return "Turen har undantag och har inte kört hela vägen.";
		}

		return "Bekfräftad H19 som är vitesbelagd";
	};

	const cancelledStatus = (row: any) => {
		if (row.status === "resend-failed") {
			return Math.random() > 0.5 ? "GPS fel" : "Turen hoppar in fel";
		}

		if (row.status === "cancelled") {
			return "Inställd";
		}

		if (row.status === "exception") {
			return "Undantag";
		}

		return "Bekfräftad H19";
	};

	const getH19Status = (row: any) => {
		if (row.status === "waiting-data") {
			return Math.random() > 0.5 ? "GPS fel" : "Turen hoppar in fel";
		}

		if (row.status === "cancelled") {
			return "Turen är inställd";
		}

		if (row.status === "exception") {
			return "Undantag";
		}

		return "Bekfräftad H19";
	};

	const getH19StatusFULL = (status: any, resent: boolean, oneHourDiff: boolean) => {
		if (oneHourDiff && resent && status === "false-h19") {
			return "Eftersänd med felaktig tidzon";
		}

		return STATUS_TITLE_MAP[status];
	};

	const getH19StatusComment = (row: any) => {
		if (row.status === "waiting-data") {
			return Math.random() > 0.5
				? "GPS postioner hoppar felaktigt iväg"
				: "Bussen hoppar in fel på turen";
		}

		if (row.status === "cancelled") {
			return "Turen är vitesbelagd och inställd";
		}

		if (row.status === "exception") {
			return "Turen har undantag och har inte kört hela vägen. Bussen avregistrerade inte ifrån turen";
		}

		return "Bekfräftad H19 som är vitesbelagd";
	};

	const genSpecTrips = async (contact: string) => {
		console.log("Generate production");

		const response = await postman.get(`planneddeviatedtrips/${contact}/${month}`);

		if (!response.data) {
			console.log("No data found");
			return;
		}

		// HEADERS: Datum	Linje	Tur	Planerad KM	Planerad tid	Från HPL	Till HPL	SlutHPL	Påverkade km	Påverkade minuter	% inställd
		const data = response.data;

		const specialTrips = [
			[
				"Trafikdatum",
				"Linje",
				"Tur",
				"Planerad KM",
				"Planerad tid",
				"Från HPL",
				"Till HPL",
				"SlutHPL",
				"Påverkade km",
				"Påverkade minuter",
				"% inställd",
			],
			...data.map((row) => [
				row.operatingDay,
				row.line,
				row.trip,
				Math.round(row.plannedDistance / 1000),
				row.plannedTime,
				row.startStop,
				row.endStop,
				row.deviationStartStop,
				Math.round((row.plannedDistance - row.actualDistance) / 1000),
				Math.round(row.plannedTime - row.actualTime),
				Math.round((row.actualDistance / row.plannedDistance) * 100),
			]),
		];

		const wb = utils.book_new();
		const specialWs = utils.aoa_to_sheet(specialTrips);

		utils.book_append_sheet(wb, specialWs, contact);

		// Create file.
		createXLSXFile(wb, `special-trips-${contact}-${month}.xlsx`);
	};

	const caluclateIfFailedResend = async (h19Trips: any[]) => {
		const keyedResult: any = {};

		// Fetch resent trips and key them.
		const resentTrips: any = (await postman.get("/resenttrips"))?.data;

		if (!resentTrips) {
			console.log("No data found");
			alert("No data found");
			return;
		}

		const resentTripsKeyed = {};

		for (let i = 0; i < resentTrips.length; i++) {
			const resent = resentTrips[i];
			const trips = JSON.parse(resent.trips);

			for (let j = 0; j < trips.length; j++) {
				const tripRow = trips[j];
				const [_, operatingDay, line, trip] = tripRow.split("-");
				const key = `${operatingDay}-${line.substring(0, 3)}-${trip}`;

				resentTripsKeyed[key] = true;
			}
		}

		for (let i = 0; i < h19Trips.length; i++) {
			const trip = h19Trips[i];
			const key = `${trip.operatingDay}-${trip.line}-${trip.trip}`;
			const deviatedStopsKeyed = {};
			const deviatedStopsByTime = {};

			if (trip.calls.length === 0) {
				console.log("No calls", trip);
				continue;
			}

			for (let i = 0; i < trip.deviatedStops.length; i++) {
				const deviatedStop = trip.deviatedStops[i];

				deviatedStopsKeyed[deviatedStop.name] = deviatedStop;

				const actualTimeAdjusted =
					"T" +
					caluclateDateString(
						trip.calls[0].operatingDay,
						deviatedStop.plannedTime
					).toLocaleTimeString();
				const [TFHurs, TFMinutes] = getDateHoursAndMinutes(actualTimeAdjusted);
				deviatedStopsByTime[TFHurs + ":" + TFMinutes] = deviatedStop;
			}

			let has1hourDelayAtleastOneStop = false;

			for (let j = 0; j < trip.calls.length; j++) {
				const stop = trip.calls[j];
				const [P_TTHurs, P_TTMinutes] = getDateHoursAndMinutes(
					stop.plannedArrivalTime ?? stop.plannedDepartureTime
				);
				const deviatedStop =
					deviatedStopsKeyed[stop.stopName] ??
					deviatedStopsByTime[P_TTHurs + ":" + P_TTMinutes];

				if (
					!deviatedStop ||
					(stop.actualArrivalTime === null && stop.actualDepartureTime === null)
				) {
					continue;
				}

				const actualTimeAdjusted =
					"T" +
					caluclateDateString(
						stop.operatingDay,
						deviatedStop.actualTime
					).toLocaleTimeString();
				const [TFHurs, TFMinutes] = getDateHoursAndMinutes(actualTimeAdjusted);
				const [TTHurs, TTMinutes] = getDateHoursAndMinutes(
					stop.actualArrivalTime ?? stop.actualDepartureTime
				);

				if (TFHurs === TTHurs + 1 && TFMinutes === TTMinutes) {
					has1hourDelayAtleastOneStop = true;
					break;
				}
			}

			keyedResult[key] = {};
			keyedResult[key].resent = !!resentTripsKeyed[key];
			keyedResult[key].oneHourDiffOne = !!has1hourDelayAtleastOneStop;
		}

		return keyedResult;
	};

	const generateH19 = async () => {
		const trips: any = (await postman.get(`/h19s/${contract}/${month}/${1}`))?.data;

		if (!trips) {
			console.log("No data found");
			alert("No data found");
			return;
		}

		const trips_to_caluclate = trips;

		const FIXED_TRIPS = [];

		const tripDiffStatus = await caluclateIfFailedResend(trips_to_caluclate);

		for (let i = 0; i < trips_to_caluclate.length; i++) {
			const trip = trips_to_caluclate[i];
			const { startStop, endStop, minutesLate, timeDiffBetweenStops, diff } =
				calculateH19Stats(trip);

			const rtId = `RT116${Math.random().toString().substring(2, 6)}`;

			FIXED_TRIPS.push({
				operatingDay: trip.operatingDay,
				line: trip.line,
				trip: trip.trip,
				vehicle: trip.vehicle,
				contract: trip.contract,
				rtId: rtId,
				start: startStop.stopName,
				end: endStop.stopName,
				late: minutesLate.toFixed(2),
				diff: timeDiffBetweenStops > 1 ? timeDiffBetweenStops.toFixed() : 2,
				distance: ((trip.plannedDistance / 1000) * (diff === 0 ? 0.2 : diff)).toFixed(2),
				reason: "",
				description: trip.description ?? "",
				tripResent:
					tripDiffStatus[`${trip.operatingDay}-${trip.line}-${trip.trip}`]?.resent,
				oneHourDiffOne:
					tripDiffStatus[`${trip.operatingDay}-${trip.line}-${trip.trip}`]
						?.oneHourDiffOne,
				status: trip.status,
				comment: trip.comment,
			});
		}

		const h19Data = [
			[
				"Avtal",
				"Trafikdatum",
				"Starttid",
				"Tur",
				"Linje",
				"FordonsID",
				"Från HPL",
				"SlutHPL",
				"Minuter sen",
				"Påverkade km",
				"Påverkad tid",
				"Störningsnummer",
				"Orsak",
				"Intern kommentar",
				"Gulmarkerad kommentar",
				"Eftersänd tur",
				"Minst en stopp > 1h",
				"Status",
			],
			...FIXED_TRIPS.map((row) => [
				TRIP_TRAFFIC_AREA_OWBER[row.line],
				row.operatingDay,
				`${row.trip.substring(1, 3)}:${row.trip.substring(3, 5)}`,
				row.trip,
				row.line,
				row.vehicles ?? zeroed(getRandomNumber(TRIP_TRAFFIC_AREA_OWBER[row.line], row)),
				row.start,
				row.end,
				row.late,
				row.distance,
				row.diff,
				row.rtId,
				row.reason,
				row.internalComment,
				"",
				row.tripResent,
				row.oneHourDiffOne,
				getH19StatusFULL(row.status, row.tripResent, row.oneHourDiffOne) + (row.comment ? `, ${row.comment}` : ""),
			]),
		];

		const wb = utils.book_new();
		const partialWs = utils.aoa_to_sheet(h19Data);
		utils.book_append_sheet(wb, partialWs, "H!9");

		createXLSXFile(wb, `production-h19-${month}.xlsx`);
	};

	return (
		<div className="flex flex-1 px-6 flex-col">
			<div className="flex w-full justify-between items-center mb-6">
				<div>
					<h1 className="mt-12 font-semibold text-2xl">Rapporter</h1>
					<p className="font-medium text-stone text-sm w-40 text-stone-500 whitespace-nowrap">
						Här kan du skapa rapporter för en specifik månad
					</p>
				</div>
				<div className="flex">
					<MonthSelector value={month} onChange={setMonth} />
				</div>
			</div>

			<div className="flex flex-1 flex-col w-full items-start">
				<p className="mb-2">Generera dataanalysunderlag</p>
				<div className="-ml-2">
					{/* <InputFile name="trips" accept=".xlsx" onHandleFile={handleH19Consat} /> */}
					<Button
						text="Generera rapport"
						color="blue"
						disabled
						onClick={() => generateAnalysis()}
					></Button>
				</div>
				<p className="mb-2 mt-8">Generera leveransunderlag</p>
				<div className="-ml-2">
					{/* <Input value={contract} onChange={setContract} placeholder="Config" />
					<Textarea
						value={cancelledTrips}
						onChange={handleCancelledTrips}
						placeholder="config 2"
					/>
					{cancelledTrips ? <p>{cancelledTrips.length}dagar</p> : null} */}
					<Button
						text="Generera rapport"
						color="blue"
						disabled={true}
						onClick={() => generateProduction()}
					></Button>
				</div>

				<p className="mb-2 mt-8">Generera h19 underlag</p>
				<div className="-ml-2">
					<Button
						text="Generera rapport"
						color="blue"
						onClick={() => generateH19()}
					></Button>
				</div>

				<p className="mb-2 mt-8">Generera specialturer</p>
				<div className="-ml-2">
					<Button
						text="Generera turer E31"
						color="blue"
						onClick={() => genSpecTrips("E31")}
					></Button>
					<Button
						text="Generera turer E38"
						color="blue"
						onClick={() => genSpecTrips("E38")}
					></Button>
				</div>
			</div>
		</div>
	);
}
