export default function Popup({
	show,
	setShow,
	children
}: {
	show: boolean;
	setShow: any;
	children: any;
}) {
	return show ? (
		<div className="absolute z-10 top-0 bottom-0 right-0 left-0 w-full h-screen flex justify-center items-center">
			<div className="flex bg-white justify-center items-center p-4 rounded-lg">
				{children}
			</div>
			<div
				onClick={() => setShow(false)}
				className="bg-black w-full h-full absolute top-0 opacity-40 -z-10"
			></div>
		</div>
	) : null;
}
