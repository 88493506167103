import { useState } from "react";

import usePersistentState from "../helper/usePersistentState";
import { useParams } from "wouter";
import { prettyTime } from "../helper/date";

const orderDefaultdata = {
	orderTimestamp: "2024-12-17 14:00",
	Reason: "Spårarbete",
	TrainNumber: 2914,
	Type: "Pendel",
	BusesOrdered: 4,
	ScheduledDepartureTime: "2024-12-17 14:45",
	FromStation: "Täby Centrum",
	ToStation: "Stockholm Östra",
	OrderedBusesCount: 4,
	ViaStations: "Djursholms Ösby, Mörby",
	Comment: "",
	RegistrationNumber: "LMN456, LMN456, LMN456, LMN456",
	BusesArrived: 0,
	FirstBusArrivalApprox: "",
	ETA: "",
	ATA: "",
	ATD: "",
	EndTime: "",
};

const createStatus = (trip: any) => {
	let style = "";
	let status = "";

	if (trip.vehicles.length === 0) {
		status = "Inväntar tillsättning";
		style = "text-amber-800 bg-amber-200";
	} else if (trip.completed) {
		status = "Slutförd";
		style = "text-green-800 bg-green-200";
	} else if (trip.busesOrdered !== trip.vehicles.length) {
		status = "Pågår";
		style = "text-green-800 bg-green-200";
	} else {
		status = "Pågår";
		style = "text-green-800 bg-green-200";
	}

	return <p className={"font-semibold px-2 py-1 rounded text-xs " + style}>{status}</p>;
};

function ReplacementTripOrderDetails() {
	// Get params from URL
	const { tripId } = useParams<{ tripId: any }>();

	// State for buses assigned to the order

	const [fakeData, setFakeData] = usePersistentState<any>("fakedata", []);

	// Handler to update bus registration numbers
	const handleBusChange = (index: number, value: any) => {
		const updatedBuses = [...buses];
		updatedBuses[index] = value;
		setBuses(updatedBuses);
		setFakeData({ ...fakeData,
			[tripId]: {
				...fakeData[tripId],
				vehicles: updatedBuses,
			},
		  });
	};

	// Add a new input for a bus
	const addBus = (index: any, value: any) => {
		const newArray = [...buses, ""];
		setBuses(newArray);
	};

	// Remove a bus input
	const removeBus = (index: number) => {
		const vehicles = buses.filter((_: any, i: any) => i !== index);
		setBuses(vehicles);
		setFakeData({ ...fakeData,
			[tripId]: {
				...fakeData[tripId],
				vehicles: vehicles,
			},
		  });
	};

	// Submit handler
	const handleSubmit = (e: any) => {
		e.preventDefault();
		// Logic to save bus assignments or update the database
		setBuses([...buses, ""]);
	};

	const trip = fakeData[tripId];

	const scheduledDepartureTime = new Date(trip.scheduledDepartureTime).getTime();

	const [buses, setBuses] = useState<any>(trip.vehicles);

	console.log(buses)

	return (
		<div className="container mx-auto px-4 py-8">
			<h1 className="text-2xl font-bold mb-4">Orderdetaljer</h1>
			<div className="p-4 flex justify-between items-center rounded">
				<div>
					<p className="font-bold">{prettyTime(scheduledDepartureTime)}</p>
					<p className="text-sm">{trip.type}</p>
					<p className="text-sm">{trip.busesOrdered} bussar beställda</p>
					<p className="text-sm">{trip.stations.join(", ")}</p>
				</div>
				<div className="flex flex-row items-center w-40">{createStatus(trip)}</div>
			</div>

			{/* <div className="flex flex-1 px-6 flex-col">
				<div className="flex flex-col w-3/4">
					<MapContainer center={[59.43926662897951, 18.084435863103256]} zoom={11}>
						<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
					</MapContainer>
				</div>
			</div> */}
			<h2 className="text-xl font-bold mt-8">Lägg till bussar</h2>
			<form onSubmit={handleSubmit} className="mt-4">
				{buses.map((bus: any, index: any) =>
					index < trip.busesOrdered ? (
						<div key={index} className="flex items-center mb-2">
							<input
								type="text"
								className="border p-2 rounded text-sm"
								placeholder={`Buss ${index + 1}`}
								value={bus}
								onChange={(e) => handleBusChange(index, e.target.value)}
							/>
							{buses.length !== 1 ? (
								<button
									type="button"
									className="ml-2 text-xs h-10 font-semibold bg-white text-red-500 border border-red-500 px-4 py-2 rounded"
									onClick={() => removeBus(index)}
								>
									Ta bort
								</button>
							) : null}
						</div>
					) : null
				)}
				{buses.length < trip.busesOrdered ? (
					<button
						type="button"
						className="mt-4 bg-blue-100 text-blue-500 font-medium px-4 py-2 rounded mr-2"
						
					>
						Lägg till buss
					</button>
				) : null}
				{/* <button type="submit" className="mt-4 bg-green-100 font-medium text-green-500 px-4 py-2 rounded">
					Spara tilldelning
				</button> */}
			</form>
		</div>
	);
}

export default ReplacementTripOrderDetails;
