export default function Modal({
	show,
	children,
	closeModal,
}: {
	show: boolean;
	children: any;
	closeModal: any;
}) {
	return show ? (
		<div className="top-0 left-0 w-full z-30 fixed h-full flex flex-1 justify-center items-center px-10">
			<div className="bg-white shadow-md z-50 rounded dark:bg-stone-950 flex justify-center items-center">
				{children}
			</div>
			<div
				className="top-0 left-0 absolute w-full h-full bg-black opacity-20"
				onClick={() => closeModal()}
			/>
		</div>
	) : null;
}
