import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const endStationnMap: any = {
	Nbp: true,
	Ösk: true,
	Kår: true,
};

const TrainGraph = ({
	trips,
	stations,
	dimensions,
}: {
	trips: any[];
	stations: any[];
	dimensions: { xStart: number; xEnd: number; yStart: number; yEnd: number };
}) => {
	const svgRef: any = useRef("");

	console.log(trips);

	useEffect(() => {
		const margin = { top: 30, right: 5, bottom: 30, left: 35 };
		const width = 1700 - margin.left - margin.right;
		const height = 50 * stations.length - margin.top - margin.bottom;

		const svg = d3
			.select(svgRef.current)
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("transform", `translate(${margin.left},${margin.top})`);

		const totalDistanceBetweenStations =
			stations[0].distance - stations[stations.length - 1].distance;

		const stationsDistanceMap: any = {};

		for (let i = 0; i < stations.length; i++) {
			stationsDistanceMap[stations[i].name] =
				stations[i].distance - stations[stations.length - 1].distance;
		}

		// Create scales
		const xScale = d3
			.scaleLinear()
			.domain([dimensions.xStart, dimensions.xEnd])
			.range([margin.left, width - margin.right]);

		const yScale: any = d3
			.scaleLinear()
			.domain(stations.map((station) => station.name))
			.range([0, height]);

		// Check if the scales are defined
		if (xScale === undefined || yScale === undefined) {
			return;
		}

		// Draw vertical lines at whole hours
		const maxTime = d3.max(trips, (trip) => trip.endTime);
		const numHours = Math.ceil(maxTime / 60);
		const hourPositions = Array.from({ length: numHours }, (_, i) => i * 60);
		const tenMinPositions = Array.from({ length: numHours * 6 }, (_, i) => i);
		const minutePositions = Array.from({ length: numHours * 30 }, (_, i) => i);

		svg.selectAll(".minute-line")
			.data(minutePositions)
			.enter()
			.append("line")
			.attr("class", "minute-line")
			.attr("x1", (d) => xScale(d * 2))
			.attr("y1", 0)
			.attr("x2", (d) => xScale(d * 2))
			.attr("y2", height)
			.attr("stroke", "#ddd")
			.attr("stroke-width", 0.5);

		svg.selectAll(".ten-minute-line")
			.data(tenMinPositions)
			.enter()
			.append("line")
			.attr("class", "ten-minute-line")
			.attr("x1", (d) => xScale(d * 10))
			.attr("y1", 0)
			.attr("x2", (d) => xScale(d * 10))
			.attr("y2", height)
			.attr("stroke", "#888")
			.attr("stroke-width", 0.5);

		svg.append("rect")
			.attr("x", -margin.left)
			.attr("y", 0)
			.attr("width", margin.left)
			.attr("height", height)
			.attr("fill", "#fff");

		svg.append("rect")
			.attr("x", width)
			.attr("y", 0)
			.attr("width", margin.right)
			.attr("height", height)
			.attr("fill", "#fff");

		// Draw vertical boundary at the end of the graph.
		svg.selectAll(".line")
			.data([0, 1])
			.enter()
			.append("line")
			.attr("x1", (x) => x * width)
			.attr("y1", 0)
			.attr("x2", (x) => x * width)
			.attr("y2", height)
			.attr("stroke", "#000")
			.attr("stroke-width", 1);

		svg.selectAll(".hour-line")
			.data(hourPositions)
			.enter()
			.append("line")
			.attr("class", "hour-line")
			.attr("x1", (d) => xScale(d))
			.attr("y1", 0)
			.attr("x2", (d) => xScale(d))
			.attr("y2", height)
			.attr("stroke", "#000")
			.attr("stroke-width", 1);

		svg.selectAll(".hour-label")
			.data(hourPositions)
			.enter()
			.append("text")
			.attr("class", "hour-label")
			.attr("x", (d) => xScale(d))
			.attr("y", -5)
			.attr("text-anchor", "middle")
			.attr("font-size", "30px")
			.attr("font-weight", "bold")
			.text((d) => (d / 60 === 0 ? 24 : d / 60));

		// Draw lines for each trip
		svg.selectAll(".trip-line")
			.data(trips)
			.enter()
			.append("line")
			.attr("class", "trip-line")
			.attr("x1", (trip) => xScale(trip.startTime))
			.attr(
				"y1",
				(trip) =>
					height -
					height *
						(stationsDistanceMap[
							trip.startStation.replace("Ank ", "").replace("Avg ", "")
						] /
							totalDistanceBetweenStations)
			)
			.attr("x2", (trip) => xScale(trip.endTime))
			.attr(
				"y2",
				(trip) =>
					height -
					height *
						(stationsDistanceMap[
							trip.endStation.replace("Avg ", "").replace("Ank ", "")
						] /
							totalDistanceBetweenStations)
			)
			.attr("stroke", (trip) => {
				const [periodHour, periodMin] = trip.periodTime.split(":");
				const period = parseInt(periodHour) * 60 + parseInt(periodMin);

				if (period === 15) {
					console.log("RAN");
					return "#4f34eb";
				} else if (period === 20) {
					return "#eb4334";
				} else {
					return "#000";
				}
			})
			.attr("stroke-dasharray", (trip) => trip.mallTrain.charAt(2) === "S" ? "5" : "0");

		// Draw horizontal lines for station connections
		svg.selectAll(".station-line")
			.data(stations) // Exclude the first station
			.enter()
			.append("line")
			.attr("class", "station-line")
			.attr("x1", 0)
			.attr(
				"y1",
				(station) =>
					height -
					height *
						((station.distance - stations[stations.length - 1].distance) /
							totalDistanceBetweenStations)
			)
			.attr("x2", width)
			.attr(
				"y2",
				(station) =>
					height -
					height *
						((station.distance - stations[stations.length - 1].distance) /
							totalDistanceBetweenStations)
			)
			.attr("stroke", "#000")
			.attr("stroke-width", 1)
			.attr("stroke-dasharray", "0");

		// Render station labels
		svg.selectAll(".station-label")
			.data(stations)
			.enter()
			.append("text")
			.attr("class", "station-label")
			.attr("x", -6)
			.attr(
				"y",
				(station) =>
					height -
					height *
						((station.distance - stations[stations.length - 1].distance) /
							totalDistanceBetweenStations)
			)
			.attr("text-anchor", "end")
			.attr("font-size", "14px")
			.attr("font-weight", "bold")
			.text((station) => station.name.replaceAll("Avg ", "").replaceAll("Ank ", ""));
	}, [trips]);

	return <svg ref={svgRef}></svg>;
};

const formatTime = (time: number) => {
	const minutes = time % 60;
	return minutes !== 0 ? `${minutes}` : ``;
};

export default TrainGraph;
