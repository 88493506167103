import { useState } from "react";

const CONTRACTS: any = [
	"Alla",
	"E31",
	"E35",
	"E38",
];

const CONTRACTS_MAP: any = {
	Alla: "ALL",
	E31: "E31",
	E35: "E35",
	E38: "E38",
};

export default function ContractSelector({
	value,
	onChange,
}: {
	value: string;
	onChange: any;
}) {
	const year = value.slice(0, 4);
	const month = value.slice(4, 6);
	const [open, setOpen] = useState(false);

	return (
		<div className="relative select-none">
			<span className="font-semibold text-blue-600 text-opacity-50 text-xs ml-1 px-1 absolute -top-4 dark:text-stone-200">
				Division
			</span>
			<div
				onClick={() => setOpen(!open)}
				className="flex ml-2 px-6 py-2 bg-blue-100 text-blue-600 dark:bg-blue-950 dark:text-blue-400 font-medium rounded text-sm cursor-pointer hover:bg-blue-50 transition-colors ease-linear"
			>
				{value}
			</div>
			{open && (
				<div className="absolute top-0 right-0 mt-8 bg-white dark:bg-stone-800 border border-stone-200 dark:border-stone-800 rounded shadow-lg">
					<div className="flex flex-col">
						{CONTRACTS.map((C) => (
							<div
								key={C}
								className="p-2 text-center cursor-pointer hover:bg-stone-100 dark:hover:bg-stone-700"
								onClick={() => {
									onChange(CONTRACTS_MAP[C]);
									setOpen(false);
								}}
							>
								{C}
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
