import { useState } from "react";
import { fetchInfoDisplys, fetchUsers } from "../helper/network";
import Modal from "../components/modal";
import Input from "../components/input";
import postman from "../helper/postman";
import InputSelect from "../components/inputSelect";
import { Link } from "wouter";
import { safeUserStorage } from "../helper/storage";

const userRoles: any = [
	["admin", "Admin"],
	["user", "User"],
];

const permissions: any = [];

export default function Users() {
	const { data, mutate } = fetchUsers();
	const [showCreateUser, setShowCreateUser] = useState(false);
	const user = JSON.parse(safeUserStorage() || "{}");

	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [role, setRole] = useState(""); // ["admin", "user"
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (!firstname || !lastname || !username || !password || !role) {
			alert("Fyll i alla fält");
			return;
		}

		console.log("Creating user");

		try {
			await postman.post("/users", {
				firstname,
				lastname,
				role,
				username,
				password,
			});

			mutate(
				(data: any) => [
					...data,
					{
						Firstname: firstname,
						Lastname: lastname,
						Role: role,
						Username: username,
						Password: "",
						Permissions: [],
					},
				],
				false
			);

			setShowCreateUser(false);
			resetFields();
		} catch (error: any) {
			// Check if network error or badrequest.
			if (error?.response?.status === 400) {
				alert(
					"Användarnamnet är upptaget eller så finns det redan en användare med det namnet"
				);
			} else {
				alert("Något gick fel");
			}
		}
	};

	const resetFields = () => {
		setFirstname("");
		setLastname("");
		setUsername("");
		setPassword("");
		setRole("");
	};

	const handleCancel = () => {
		setShowCreateUser(false);
		resetFields();
	};

	return (
		<div className="flex flex-1 flex-col">
			<Modal show={showCreateUser} closeModal={() => setShowCreateUser(false)}>
				<form
					className="flex flex-col justify-betweem w-full h-full items-center py-12 px-20"
					onSubmit={handleSubmit}
				>
					<div className="flex flex-col items-center mb-6">
						<h1 className="font-medium text-xl">Skapa användare</h1>
						<p className="mt-2 text-slate-500 text-sm">
							Fyll i fälten nedan för att skapa en användare
						</p>
					</div>
					<div className="mt-6 w-full">
						<Input placeholder="Förnamn" value={firstname} onChange={setFirstname} />
					</div>
					<div className="mt-6 w-full">
						<Input placeholder="Efternamn" value={lastname} onChange={setLastname} />
					</div>
					<div className="mt-6 w-full">
						<InputSelect
							items={userRoles}
							onChange={setRole}
							placeholder="Roll"
							value={role}
						/>
					</div>
					<div className="mt-6 w-full">
						<Input placeholder="Användarnamn" value={username} onChange={setUsername} />
					</div>
					<div className="mt-6 w-full">
						<Input
							placeholder="Lösenord"
							type="password"
							value={password}
							onChange={setPassword}
						/>
					</div>
					{/* <div className="mt-6 w-full">
						<InputSelect
							items={permissions}
							onChange={setRole}
							placeholder="Rättigheter"
							value={role}
						/>
					</div> */}

					<div className="flex flex-row ] mt-6 justify-center items-center">
						<div
							className="bg-slate-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:text-black"
							onClick={() => {
								handleCancel();
							}}
						>
							Avbryt
						</div>
						<input
							type="submit"
							className="bg-blue-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 ml-6 dark:text-black dark:bg-blue-400"
							value="Skapa användare"
						/>
					</div>
				</form>
			</Modal>
			<h1 className="font-bold text-2xl mt-12 mb-3">Hantera användare</h1>
			<div className="flex justify-end">
				<div
					className={
						"bg-blue-200 self-start px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700 " +
						(user.role === "user" ? "hidden" : "")
					}
					onClick={() => setShowCreateUser(true)}
				>
					Lägg till användare
				</div>
			</div>
			<div className="flex flex-1 flex-col justify-start items-center mt-6">
				{data ? (
					data?.map((user: any) => (
						<Link
							className="flex flex-row w-full justify-between items-center py-2 border-b border-gray-200 hover:bg-slate-100 dark:border-stone-900 dark:hover:bg-stone-900 px-4 cursor-pointer transition-all"
							key={"user-" + user.Username}
							to={"/users/" + user.Username}
						>
							<div className="flex flex-col">
								<p className="text-sm text-slate-500 dark:text-stone-500">{user.Username}</p>
								<p className="font-semibold">
									{user.Firstname} {user.LastName}
								</p>
								<p className="text-sm text-slate-500 dark:text-stone-500">{user.Role}</p>
							</div>
						</Link>
					))
				) : (
					<p className="mt-10 text-slate-500 font-medium">Inga användare hittades...</p>
				)}
			</div>
		</div>
	);
}
