import { useState } from "react";
import { prettyFullDate, prettyTime } from "../../helper/date";
import useFetch from "../../helper/swr";

const getStatusColor = (contractDay: any, lastUpdated, isH19: boolean) => {
	if (lastUpdated === "") {
		return "bg-stone-200 hover:bg-stone-300 dark:bg-stone-400";
	}

	if (!contractDay) {
		return isH19
			? "bg-yellow-400 hover:bg-yellow-300 dark:bg-yellow-600"
			: "bg-red-500 hover:bg-red-400 dark:bg-red-700";
	}

	if (parseInt(contractDay.dev) < 500) {
		return "bg-yellow-400 hover:bg-yellow-300 dark:bg-yellow-600";
	}

	return "bg-green-500 hover:bg-green-400 dark:bg-green-600";
};

const prettyLastUpdated = (lastUpdated: string) => {
	if (lastUpdated === "") {
		return <p className="text-xs text-stone-700">Senast uppdaterad: -</p>;
	}

	return (
		<p className="text-xs text-stone-500">
			Senast uppdaterad: {prettyTime(new Date(lastUpdated).getTime())}
		</p>
	);
};

// Props stats, isH19 and i.
const StatusDay = ({
	stats,
	isH19,
	i,
	sixtyDaysAgo,
}: {
	stats: any;
	isH19: boolean;
	i: number;
	sixtyDaysAgo: Date;
}) => {
	const [showPopup, setShowPopup] = useState(false);

	const date = new Date(sixtyDaysAgo);
	date.setDate(date.getDate() + i);

	return (
		<div
			onMouseEnter={() => setShowPopup(true)}
			onMouseLeave={() => setShowPopup(false)}
			className={
				"flex relative flex-1 h-2 rounded-sm cursor-pointer mr-0.5 " +
				getStatusColor(
					stats?.days[prettyFullDate(date.getTime()).replaceAll("-", "")],
					stats?.lastUpdated,
					isH19
				)
			}
		>
			{showPopup && (
				<div className="absolute -top-10 w-24 -left-8 text-center border dark:border-stone-800 bg-white rounded dark:bg-stone-950 dark:text-stone-200 p-2 text-xs">
					<p className="text-xs font-medium">{prettyFullDate(date.getTime())}</p>
				</div>
			)}
		</div>
	);
};

// Create a status component, it should have a title, description, last time updated, and a list of days.
const StatusRow = ({
	title,
	desc,
	stats,
	isH19,
}: {
	title: string;
	desc: string;
	stats: any;
	isH19?: boolean;
}) => {
	const sixtyDaysAgo = new Date();
	sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);

	return (
		<div className="flex flex-row w-full">
			<div className="flex w-full border rounded dark:border-stone-800 dark:bg-stone-950 box-border mb-1 px-2 py-1 font-medium flex-col">
				<div className="flex flex-row justify-between">
					<p className="text-sm font-bold">{title}</p>
					{prettyLastUpdated(stats?.lastUpdated)}
				</div>
				<p className="text-sm text-stone-700 dark:text-stone-500 -mt-1 mb-2">{desc}</p>
				<div className="mt-auto flex">
					{[...Array(60)].map((_, i) => (
						<StatusDay
							key={i}
							stats={stats}
							isH19={isH19}
							i={i}
							sixtyDaysAgo={sixtyDaysAgo}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

const StatusTable = ({ stats }: { stats: any }) => {
	if (!stats) {
		return <p className="m-auto text-stone-500">Gick ej att hämta status...</p>;
	}

	return (
		<div className="flex flex-1 flex-row w-full flex-wrap">
			<h1 className="mt-12 font-semibold text-xl mb-1">Utförd trafik</h1>
			<StatusRow
				title="E31"
				desc="Utförd TF-rapport för E31"
				stats={stats.progressReports.E31}
			/>
			<StatusRow
				title="E35"
				desc="Utförd TF-rapport för E35"
				stats={stats.progressReports.E35}
			/>
			<StatusRow
				title="E38"
				desc="Utförd TF-rapport för E38"
				stats={stats.progressReports.E38}
			/>
			<h1 className="mt-12 font-semibold text-xl mb-1">19 minuter sena avgångar</h1>
			<StatusRow
				title="E31"
				desc="H19 TF-rapport för E35"
				stats={stats.h19Reports.E31}
				isH19
			/>
			<StatusRow
				title="E35 E38"
				desc="H19 TF-rapport för E35/E38"
				stats={stats.h19Reports.E35E38}
				isH19
			/>

			<h1 className="mt-12 font-semibold text-xl mb-1">Övrigt</h1>
			<StatusRow
				title="Uppdatera turer"
				desc="Lägg till data för de som saknar data"
				stats={stats.resend.updateTrips}
			/>
			<StatusRow
				title="Planerade avvikande turer"
				desc="574 osv, turer som inte behöver köras hela sträckan"
				stats={stats.resend.plannedDeviatedTrips}
			/>
			<StatusRow
				title="Bookningsbara turer"
				desc="Anropstyrdtrafik, turer som kan bokas"
				stats={stats.resend.bookingTrips}
			/>
			<StatusRow
				title="Generera eftersänding"
				desc="Skapa turer att eftersända"
				stats={stats.resend.generateTrips}
			/>
			<StatusRow
				title="Skicka eftersänding"
				desc="Skicka turer som är eftersända"
				stats={stats.resend.resendTrips}
			/>
		</div>
	);
};

export default function Status() {
	const { data: stats, isLoading } = useFetch("resend/status");

	return (
		<div className="flex flex-1 px-6 flex-col">
			<div className="flex w-full justify-between items-center mb-6">
				<div>
					<h1 className="mt-12 font-semibold text-2xl">Systemstatus</h1>
				</div>
			</div>
			<div className="flex flex-col w-full">
				{isLoading ? (
					<p className="m-auto text-blue-500">Laddar...</p>
				) : (
					<StatusTable stats={stats} />
				)}
			</div>
		</div>
	);
}
