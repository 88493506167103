export const StatBox = ({
	title,
	procentage,
	value,
}: {
	title: string;
	procentage: string;
	value: number | string;
}) => {
	return (
		<div className="flex flex-col flex-1 mx-1 mb-1 text-center bg-slate-100 dark:bg-blue-950 dark:text-blue-400 px-10 py-4 justify-center items-center max-w-40">
			<h4 className="font-semibold text-2xl">{value}</h4>
			
			<p>{title}</p>
			<p className="text-xs">({procentage})</p>
		</div>
	);
};