import { useEffect, useState } from "react";
import { fetchInfoDisplay, fetchInfoDisplys, fetchInfoPlaylists } from "../helper/network";

import Input from "../components/input";
import postman from "../helper/postman";
import InputSelect from "../components/inputSelect";
import {  useParams } from "wouter";
import CopyText from "../components/copyText";
import { navigate } from "wouter/use-browser-location";

export const INFO_DISPLAY_TYPES: any = [
	["empty", "Visa inget"],
	["bp", "Bussparkering"],
	["tf", "Trafikteknik", true],
	["info", "Informationsmeddelanden"],
];

export default function InfoDisplay({}) {
	const { displayId } = useParams<{ displayId: string }>();

	const { data, mutate: mutateDisplay } = fetchInfoDisplay(displayId);
	const { mutate: mutateDisplays } = fetchInfoDisplys();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [type, setType] = useState("");
	const [typeId, setTypeId] = useState("");
	const [orientation, setOrientation] = useState("");
	const [config, setConfig] = useState("");

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (!name || !orientation || !type) {
			alert("Fyll i alla fält");
			return;
		}

		if (type !== "empty" && typeId === "") {
			alert("Fyll i alla fält");
			return;
		}

		try {
			await postman.put("/info/displays/" + displayId, {
				name,
				description,
				orientation,
				type,
				typeId,
				config,
			});

			mutateDisplays(
				(data: any) =>
					data.map((d: any) =>
						d.Id === displayId
							? {
									Id: displayId,
									Name: name,
									Descriptio: description,
									Orientation: orientation,
									Type: type,
									TypeId: typeId,
									Config: config,
							  }
							: d
					),
				false
			);

			mutateDisplay((data: any) => ({
				...data,
				Name: name,
				Descriptio: description,
				Orientation: orientation,
				Type: type,
				TypeId: typeId,
				Config: config,
			}));
		} catch (error: any) {
			// Check if network error or badrequest.
			if (error?.response?.status === 400) {
				alert("Något gick fel. Kontrollera att du har fyllt i alla fält korrekt");
			} else {
				alert("Något gick fel");
			}
		}
	};

	const handleSetType = (value: any) => {
		setTypeId("");
		setType(value);
	};

	const handleDeleteDisplay = async () => {
		if (window.confirm("Är du säker på att du vill radera denna skärm?")) {
			try {
				await postman.delete("/info/displays/" + displayId);

				mutateDisplays((data: any) => data.filter((d: any) => d.Id !== displayId), false);

				navigate("/infodisplays");
			} catch (error: any) {
				alert("Något gick fel");
			}
		}
	};

	useEffect(() => {
		if (data) {
			setName(data.Name);
			setDescription(data.Description);
			setType(data.Type);
			setTypeId(data.TypeId ?? "");
			setOrientation(data.Orientation);
			setConfig(data.Config ?? "");
		}
	}, [data]);

	return data ? (
		<div className="flex flex-1 flex-col">
			<form
				className="flex flex-col justify-betweem w-full h-full items-center py-12 px-20"
				onSubmit={handleSubmit}
			>
				<div className="flex flex-col mb-6 self-start">
					<h1 className="font-medium text-xl">{name}</h1>
					<CopyText value={data.Id} placeholder="id">
						<p className="text-sm text-gray-500">{displayId}</p>
					</CopyText>
				</div>
				<div className="mt-6 w-full">
					<Input placeholder="Namn" value={name} onChange={setName} />
				</div>
				<div className="mt-6 w-full">
					<Input
						placeholder="Beskrivning"
						value={description}
						onChange={setDescription}
					/>
				</div>

				<div className="mt-6 w-full">
					<InputSelect
						items={[
							["l", "Lodrät"],
							["v", "Vågrät"],
						]}
						onChange={setOrientation}
						placeholder="orientation"
						value={orientation}
					/>
				</div>

				<div className="mt-6 w-full">
					<InputSelect
						items={INFO_DISPLAY_TYPES}
						onChange={handleSetType}
						placeholder="Typ"
						value={type}
					/>
					<div className="mt-6 w-full">
						<TypeInput
							type={type}
							typeId={typeId}
							setTypeId={setTypeId}
							config={config}
							setConfig={setConfig}
						/>
					</div>
				</div>

				<div className="flex flex-col ] mt-6 justify-center items-center">
					<input
						type="submit"
						className="bg-blue-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:text-black"
						value="Spara ändringar"
					/>

					<div
						className="bg-red-300 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 mt-4 dark:text-black"
						onClick={() => {
							handleDeleteDisplay();
						}}
					>
						Radera skärm
					</div>
				</div>
			</form>
		</div>
	) : (
		<p></p>
	);
}

export const TypeInput = ({
	type,
	typeId,
	setTypeId,
	setConfig,
	config,
}: {
	type: string;
	typeId: string;
	setTypeId: (typeId: string) => void;
	setConfig: (config: string) => void;
	config: string;
}) => {
	if (type === "tf") {
		// Items should be busdepots
		return (
			<InputSelect
				items={[
					["alla", "Alla"],
					["norrort", "Norrort"],
					["norrtälje", "Norrtälje"],
					["märsta", "Märsta"],
				]}
				required
				onChange={setTypeId}
				placeholder="Område"
				value={typeId}
			/>
		);
	} else if (type === "info") {
		const { data, notFound, isLoading } = fetchInfoPlaylists();

		return !isLoading ? (
			!notFound && data && data.length !== 0 ? (
				<InputSelect
					items={[...data.map((playlist: any) => [playlist.Id, playlist.Name])]}
					required
					onChange={setTypeId}
					placeholder="Spellista"
					value={typeId}
				/>
			) : (
				<p className="mt-3 text-slate-500 font-medium">Inga spellistor skapade</p>
			)
		) : (
			<p className="mt-3 text-slate-500 font-medium">Laddar spellistor...</p>
		);
	} else if (type === "bp") {
		return (
			<>
				<InputSelect
					items={[
						["taga", "TÄGA"],

						["noga", "NOGA"],

						["maga", "MÄGA"],
					]}
					required
					onChange={setTypeId}
					placeholder="Område"
					value={typeId}
				/>
				<div className="mt-6 w-full">
					<InputSelect
						items={[
							["all", "Visa all data"],
							["basic", "Visa endast grunddata"],
						]}
						required
						onChange={setConfig}
						placeholder="Extra data"
						value={config}
					/>
				</div>
			</>
		);
	}

	return null;
};
