import Button from "../../components/button";
import { Link, useLocation, useParams } from "wouter";

/* import { MapContainer, TileLayer } from "react-leaflet";
import Map from "../data/mapData";
import PR from "../data/pr";
import { CustomMarker } from "../../components/customMakrer"; */
import { Circle, MapContainer, Polyline, TileLayer } from "react-leaflet";
import { CustomMarker } from "../../components/customMarker";
import postman from "../../helper/postman";
import { useEffect, useState } from "react";
import { REASON_TITLE_MAP, STATUS_COLOR_MAP, STATUS_TITLE_MAP } from "../../config";
import useFetch from "../../helper/swr";
import Input from "../../components/input";
import InputSelect from "../../components/inputSelect";
import { TripStatus } from "./h19Trips";
import toast from "react-hot-toast";

const TYPES = {
	md: "Saknar turdata",
	h19: "H19",
};

const TYPE_URL_MAP = {
	md: "deviatedtrips",
	h19: "h19s",
};

function getTimeUntilNextFetch(): number {
	const now = new Date();
	const startHour = 8; // Fetching starts at 08:00
	const endHour = 15; // Fetching ends at 15:00
	const fetchIntervalMinutes = 15;

	const currentHour = now.getHours();
	const currentMinute = now.getMinutes();

	// If current time is before the fetching window, calculate minutes until 08:00
	if (currentHour < startHour) {
		const openingTime = new Date(now);
		openingTime.setHours(startHour, 0, 0, 0); // Set to 08:00

		return Math.ceil((openingTime.getTime() - now.getTime()) / (1000 * 60));
	}

	// If current time is after the fetching window, calculate minutes until the next day 08:00
	if (currentHour >= endHour) {
		const nextDayOpeningTime = new Date(now);
		nextDayOpeningTime.setDate(now.getDate() + 1);
		nextDayOpeningTime.setHours(startHour, 0, 0, 0); // Set to 08:00 the next day

		return Math.ceil((nextDayOpeningTime.getTime() - now.getTime()) / (1000 * 60));
	}

	// Calculate the time remaining until the next fetch interval within the window
	const minutesPastInterval = currentMinute % fetchIntervalMinutes;
	const minutesUntilNextFetch = fetchIntervalMinutes - minutesPastInterval;

	return minutesUntilNextFetch;
}

const caluclateHasProgress = (marker: any) => {
	return (
		(marker.plannedArrivalTime !== undefined && marker.actualArrivalTime) ||
		(marker.plannedDepartureTime !== undefined && marker.actualDepartureTime !== undefined)
	);
};

// Helper function to format time in hours or minutes
function formatTime(minutes: number): string {
	if (minutes >= 60) {
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
		return `${hours}h${remainingMinutes > 0 ? ` and ${remainingMinutes}min` : ""}`;
	} else {
		return `${minutes} minute(s)`;
	}
}

const caluclateH19Progress = (item: any, amount = 19) => {
	const plannedDate = new Date(item?.plannedDepartureTime ?? item.plannedArrivalTime);
	const actualDate = new Date(item?.actualDepartureTime ?? item.actualArrivalTime);

	const timeDifference = (actualDate.getTime() - plannedDate.getTime()) / (1000 * 60);

	return timeDifference > amount;
};

const getProgressColor = (item: any) => {
	if (caluclateH19Progress(item)) {
		return "bg-red-200 text-red-800";
	}

	if (caluclateH19Progress(item, 17)) {
		return "bg-yellow-200 text-yellow-800";
	}

	return "bg-green-200 text-green-800";
};

const getProgressColorCircle = (item: any) => {
	if (!item.actualArrivalTime && !item.actualDepartureTime) {
		return "gray";
	}

	if (caluclateH19Progress(item)) {
		return "red";
	}

	if (caluclateH19Progress(item, 17)) {
		return "orange";
	}

	return "green";
};

const getTimeDifference = (item: any) => {
	const plannedDate = new Date(item?.plannedDepartureTime ?? item.plannedArrivalTime);
	const actualDate = new Date(item?.actualDepartureTime ?? item.actualArrivalTime);

	const timeDifference = (actualDate.getTime() - plannedDate.getTime()) / 1000;

	return Math.abs(timeDifference) < 60
		? `${Math.round(timeDifference)}s`
		: `${Math.floor(timeDifference / 60)}m`;
};

const createDeviatedStopsWithTime = (stops) => {
	const deviatedStopsTime = {};
	const tempStops: any = Object.entries(stops);

	for (let i = 0; i < tempStops.length; i++) {
		const [_, stop] = tempStops[i];
		deviatedStopsTime[stop.plannedTime.substring(0, 5)] = stop;
	}

	return deviatedStopsTime;
};

const checkIfTFDeviated = (item: any, deviatedStops) => {
	const deviatedStopsTime = createDeviatedStopsWithTime(deviatedStops);

	if (deviatedStops[item.stopName]) {
		return (
			<p className="text-[10px] bg-red-300 px-1 py-0.5 rounded">
				{deviatedStops[item.stopName].actualTime.substring(0, 5)}
			</p>
		);
	}

	const time = (item?.plannedArrivalTime ?? item.plannedDepartureTime)
		.split("T")[1]
		.substring(0, 5);
	const stop = deviatedStopsTime[time];

	if (stop) {
		return (
			<p className="text-[10px] bg-red-300 px-1 py-0.5 rounded">
				{stop.actualTime.substring(0, 5)}
			</p>
		);
	}

	return null;
};

const caluclateStopTime = (item: any, deviatedStops) => {
	return (
		<>
			{checkIfTFDeviated(item, deviatedStops)}
			<p className={"text-right text-[10px] px-4 py-1 rounded"}>
				{(item?.plannedDepartureTime ?? item.plannedArrivalTime)
					.split("T")[1]
					.substring(0, 5)}
			</p>
			{item?.actualDepartureTime || item.actualArrivalTime ? (
				<p
					className={
						"text-center px-2 text-[10px] py-0.5 min-w-10 tex rounded bg-green-200 text-green-800 " +
						getProgressColor(item)
					}
				>
					{getTimeDifference(item)}
				</p>
			) : (
				<p className="text-center text-[10px] w-10 py-0.5 rounded bg-red-200 text-red-800">
					-
				</p>
			)}
		</>
	);
};

export default function ViewTrip() {
	interface Params {
		operatingDay: string;
		line: string;
		trip: string;
		date: string;
		type: keyof typeof TYPES;
	}

	const params = useParams<Params>();
	const [_, setLocation] = useLocation();
	const [typingTimeout, setTypingTimeout] = useState(null);

	const handleChangeTrip = ({ comment, status }: { comment: string; status: string }) => {
		if (params.type === "md") {
		} else {
			mutate((data: any) => {
				return {
					...data,
					comment,
					status,
				};
			}, false);

			postman
				.put(`h19s`, {
					operatingDay: params.operatingDay,
					line: params.line,
					trip: params.trip,
					status,
					comment,
				})
				.then(({ data }: any) => {
					toast.success("Tur sparad");

					/* const nextTrip = data.nextTrip;

					if (!nextTrip || nextTrip?.trip === undefined) {
						toast("Inga fler turer att visa");
						return;
					}

					// Go to next trip.
					setLocation(
						`/trips/${nextTrip.operatingDay}/${nextTrip.line}/${nextTrip.trip}/h19`
					); */
				})
				.catch((error) => {
					toast.error("Något gick fel");
					console.error(error);
				});
		}
	};

	const handleChangeComment = (comment) => {
		mutate((data: any) => {
			return {
				...data,
				comment,
			};
		}, false);

		// Clear the previous timeout
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}

		// Set a new timeout to save the value after 2 seconds
		setTypingTimeout(
			setTimeout(() => {
				handleChangeTrip({ comment, status: tripData.status });
			}, 750)
		);
	};

	const handleDeviation = (status: string) => {
		handleChangeTrip({ comment: tripData.comment, status });
	};

	/* useEffect(() => {
		// Funktion som hanterar tangenttryck
		const handleKeyDown = (event) => {
			event.preventDefault();

			if (event.key.toLowerCase() === "h") {
				handleDeviation("real-h19");
			} else if (event.key.toLowerCase() === "n") {
				handleDeviation("false-h19");
			}
		};

		// Lägg till en event listener
		window.addEventListener("keydown", handleKeyDown);

		// Städa upp event listener när komponenten avmonteras
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []); */

	const {
		data: tripData,
		isLoading: isLoadingTripData,
		mutate,
	} = useFetch(
		`${TYPE_URL_MAP[params.type]}/${params.operatingDay}/${params.line}/${params.trip}/view`
	);

	if (isLoadingTripData) {
		return <p>Laddar...</p>;
	}

	if (!tripData) {
		return <p>Något gick fel</p>;
	}

	return (
		<div className="flex flex-1 px-6 flex-col">
			<div className="flex flex-row mb-8 justify-between items-center">
				<div>
					<h1 className="font-semibold text-2xl mt-12">
						{tripData.line}/{tripData.trip}
					</h1>
					<p className="text-stone-500 font-semibold -my-1">
						{tripData.operatingDay.substring(0, 4)}-
						{tripData.operatingDay.substring(4, 6)}-
						{tripData.operatingDay.substring(6, 8)}
					</p>
					<p className="text-stone-500 font-semibold mb-2">{tripData.vehicle}</p>
					<p
						className={
							"rounded font-semibold text-sm py-0.5 self-auto text-center px-2 " +
							STATUS_COLOR_MAP[tripData.status]
						}
					>
						{STATUS_TITLE_MAP[tripData.status]}
					</p>
				</div>
				<Link
					to={params.type === "h19" ? "/resend/h19trips" : "/resend/notfinishedtrips"}
					className="text-stone-900 text-sm font-semibold cursor-pointer bg-stone-100 px-4 py-2 rounded"
				>
					Tillbaka
				</Link>
			</div>
			<div className="flex flex-row w-full">
				<div className="flex flex-col w-1/4 pr-4 overflow-y-scroll">
					<div className="flex flex-row justify-between px-2 py-[0.5px]">
						<p className="text-[10px] font-semibold">Hållplats</p>
						<div className="flex flex-row">
							<p className="text-[10px] mr-[20px] font-semibold">TF</p>
							<p className="text-[10px] mr-3 font-semibold">Planerad</p>
							<p className="text-[10px] mr-0.5 font-semibold">Verklig</p>
						</div>
					</div>
					{tripData?.calls
						? tripData?.calls.map((item) => (
								<div className="flex flex-row justify-between px-2 py-[0.5px]">
									<p className="text-[10px]">{item.stopName}</p>
									<div className="flex flex-row items-center">
										{caluclateStopTime(item, tripData.deviatedStops)}
									</div>
								</div>
						  ))
						: null}
				</div>
				{/* {tripData.Status === "waiting-data" ? (
					<div className="flex flex-col w-3/4">
						<p className="text-stone-500 font-semibold">Väntar på data</p>
						<p className="text-stone-500 font-semibold">
							Hämtning sker om: {formatTime(getTimeUntilNextFetch())}
						</p>
					</div>
				) : null} */}
				<div className="flex flex-col w-3/4 max-h-[calc(100vh-240px)] h-screen">
					<MapContainer
						center={
							tripData?.calls.length > 1
								? [
										tripData.calls[Math.floor(tripData?.calls.length / 2)]
											?.stopLat,
										tripData.calls[Math.floor(tripData.calls?.length / 2)]
											?.stopLon,
								  ]
								: [59.43926662897951, 18.084435863103256]
						}
						zoom={12}
					>
						<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
						{/* {tripData.vehicleData.map((marker) => (
							<CustomMarker position={[marker.latitude, marker.longitude]}>
								<div
									className="bg-red-500 rounded hover:bg-reed-200 z-40 group"
									style={{
										width: "6px",
										height: "12px",
										transform: "rotate(" + marker.heading + "deg)",
										border: "1px solid black",
									}}
								>
									<p
										className="bg-white rounded w-40 mt-5 font-bold hidden group-hover:block dark:bg-stone-950 dark:text-stone-300"
										style={{
											transform: "rotate(-" + marker.heading + "deg)",
										}}
									>
										{marker.Date}
									</p>
								</div>
							</CustomMarker>
						))} */}
						{tripData.calls.map((marker, i) => (
							<>
								{
									// Add a line between the markers, except for the last one
									i < tripData.calls.length - 1 && (
										<Polyline
											positions={[
												[marker.stopLat, marker.stopLon],
												[
													tripData.calls[i + 1].stopLat,
													tripData.calls[i + 1].stopLon,
												],
											]}
											color="black"
											opacity={
												!marker.actualArrivalTime &&
												!marker.actualDepartureTime
													? 0.4
													: 1
											}
										/>
									)
								}
								<Circle
									center={[marker.stopLat, marker.stopLon]}
									radius={50}
									fillOpacity={1}
									fillColor={getProgressColorCircle(marker)}
									color={getProgressColorCircle(marker)}
								/>
							</>
						))}
					</MapContainer>
					<div className="flex mt-5 justify-start">
						{/* {tripData?.status !== "waiting-decision" ? (
							<div>
								<p
									className={
										"font-medium whitespace-nowrap px-2 py-0.5 rounded " +
										STATUS_COLOR_MAP[tripData?.status]
									}
								>
									{STATUS_TITLE_MAP[tripData?.status]}
								</p>
								
							</div>
						) : ( */}
						<div className="flex mt-5">
							<div className="w-60 mr-2">
								<InputSelect
									placeholder="Status"
									value={tripData.status}
									onChange={handleDeviation}
									items={[
										[
											"real-h19",
											params.type === "md"
												? "Turen är inställd"
												: "Turen är h19",
										],
										["cancelled", "Inställd tur"],
										["false-h19", "Turen är utan avvikelse"],
										["-", "", true],
										["close-h19", "Nära H19"],
										["wierd-h19", "Konstig tur"],
										["looks-like-h19", "Ser ut som H19"],
										["looks-not-like-h19", "Ser inte ut som H19"],
										["unknown-h19", "Vet ej"],
										["waiting-decision", "Väntar på beslut"],
										["waiting-data", "Väntar på data"],
									]}
								/>
							</div>
							<Input
								placeholder="Kommentar"
								value={tripData.comment}
								onChange={handleChangeComment}
							/>
						</div>
						{/* )} */}
					</div>
				</div>
			</div>
		</div>
	);
}
