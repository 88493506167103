import { Link } from "wouter";
import ContractSelector from "../../components/contractSelector";
import MonthSelector from "../../components/monthSelector";
import TableHeader from "../../components/tableHeader";
import { EXCEPTIONS_TITLE_MAP, STATUS_COLOR_MAP, STATUS_TITLE_MAP } from "../../config";
import { getYearMonthFormat } from "../../helper/date";

import useFetch from "../../helper/swr";
import { TripException, TripStatus } from "../../types";
import { useState } from "react";
import { StatBox } from "../../components/statbox";
import usePersistentState from "../../helper/usePersistentState";

const HEADER_NAMES = [
	{ name: "Datum", width: "w-36" },
	{ name: "Linje", width: "w-28" },
	{ name: "Tur", width: "w-28" },
	{ name: "Utförd (%)", width: "flex-1" },
	/* { name: "Anmärkning", width: "flex-1" },  */
	{ name: "Status", width: "w-36" },
];

const TripRowExceptions = ({ exceptions }: { exceptions: TripException[] }) => {
	return exceptions.length !== 0 ? (
		<div className="flex flex-row flex-1">
			{exceptions.map((exception, i) => (
				<p
					key={"dre-" + i}
					className="font-medium px-2 py-0.5 rounded text-xs whitespace-nowrap bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200"
				>
					{EXCEPTIONS_TITLE_MAP[exception]}
				</p>
			))}
		</div>
	) : (
		<p className="flex flex-row flex-1 text-xs text-stone-400 font-medium">Inga undantag</p>
	);
};

const getStatusColor = (status: TripStatus, exceptions: string[]) => {
	if (exceptions.length !== 0) {
		return " bg-green-100 text-green-600";
	}

	return STATUS_COLOR_MAP[status];
};

const TripRowStatus = ({
	status,
	exceptions,
}: {
	status: TripStatus;
	exceptions: TripException[];
}) => {
	return (
		<div className="flex flex-row">
			<p
				className={
					"font-medium whitespace-nowrap px-1.5 py-0.5 text-xs rounded " +
					getStatusColor(status, exceptions)
				}
			>
				{STATUS_TITLE_MAP[status]}
			</p>
		</div>
	);
};

const checkIfExceptions = (trip: any): TripException[] => {
	return [];
	if (trip.line === "601") {
		return ["rerouted"];
	}

	if (trip.line === "656") {
		return ["rerouted"];
	}

	if (trip.line === "607" || trip.line === "627" || trip.line === "697") {
		return ["rerouted"];
	}

	if (trip.line === "626" && (trip.trip.startsWith("7") || trip.trip.startsWith("8"))) {
		return ["external"];
	}

	if (trip.reason === "Förstärkningstur") {
		return ["reinforcement"];
	}

	if (trip.trip.startsWith("7") || trip.trip.startsWith("8")) {
		return ["booking"];
	}

	if (trip.trip === "Trafikerar hela bara om resenär ombord") {
		return ["part-distance"];
	}

	return [];
};

export function TripRow({
	trip,
}: {
	trip: {
		operatingDay: string;
		line: string;
		trip: string;
		completeness: number;
		status: TripStatus;
	};
}) {
	return (
		<Link
			to={`/trips/${trip.operatingDay}/${trip.line}/${trip.trip}/md`}
			className="flex flex-row w-full items-center px-4 py-2 border-b hover:bg-stone-50 ease-out transition-all"
		>
			<p className="font-semibold text-sm w-36">
				{trip.operatingDay.substring(0, 4)}-{trip.operatingDay.substring(4, 6)}-
				{trip.operatingDay.substring(6, 8)}
			</p>
			<div className="flex w-28">
				<p className={"font-semibold text-sm px-1.5 rounded bg-slate-200"}>{trip.line}</p>
			</div>
			<p className="font-semibold text-sm w-28">{trip.trip}</p>
			<div className="flex flex-1">
				<p
					className={
						"font-semibold text-xs px-2 py-0.5 rounded " +
						(trip.completeness === 100 || checkIfExceptions(trip).length !== 0
							? "bg-green-200"
							: "bg-red-200")
					}
				>
					{trip.completeness}%
				</p>
			</div>
			{/* <TripRowReasons reasons={[]} /> */}
			{/* <TripRowExceptions exceptions={checkIfExceptions(trip)} /> */}
			<TripRowStatus status={trip.status} exceptions={checkIfExceptions(trip)} />
		</Link>
	);
}

const TripStats = ({
	stats,
	isLoading,
	isError,
}: {
	stats: any;
	isLoading: boolean;
	isError: boolean;
}) => {
	if (isLoading) {
		return null;
	}

	if (isError) {
		return null;
	}

	if (stats === undefined) {
		return null;
	}

	return (
		<div className="flex justify-between mb-5 flex-wrap">
			<StatBox
				title="Efter eftersändning"
				procentage={
					Number(
						100 * (1 - (stats["resend-failed"] + stats["waiting-data"]) / stats.trips)
					).toFixed(2) + "%"
				}
				value={stats["resend-failed"] + stats["waiting-data"]}
			/>

			<StatBox
				title="Innan eftersändning"
				procentage={
					Number(
						100 *
							(1 -
								(stats["resend-failed"] +
									stats["resend-cts"] +
									stats["resend-created"] +
									stats["waiting-data"]) /
									stats.trips)
					).toFixed(2) + "%"
				}
				value={
					stats["resend-failed"] +
					stats["resend-cts"] +
					stats["resend-created"] +
					stats["waiting-data"]
				}
			/>

			<StatBox
				title="Väntar på data"
				procentage={Number(100 * (stats["waiting-data"] / stats.trips)).toFixed(2) + "%"}
				value={stats["waiting-data"]}
			/>

			<StatBox
				title="Undantag"
				procentage={Number(100 * (stats["exception"] / stats.trips)).toFixed(2) + "%"}
				value={stats["exception"]}
			/>

			<StatBox
				title="Omlagd körväg"
				procentage={Number(100 * (stats["rerouted"] / stats.trips)).toFixed(2) + "%"}
				value={stats["rerouted"]}
			/>

			<StatBox
				title="Inställda"
				procentage={Number(100 * (stats.cancelled / stats.trips)).toFixed(2) + "%"}
				value={stats.cancelled}
			/>

			<StatBox
				title="Eftersända"
				procentage={
					Number(
						100 * ((stats["resend-cts"] + stats["resend-created"]) / stats.trips)
					).toFixed(2) + "%"
				}
				value={stats["resend-cts"] + stats["resend-created"]}
			/>

			<StatBox
				title="Eftersända från CTS"
				procentage={Number(100 * (stats["resend-cts"] / stats.trips)).toFixed(2) + "%"}
				value={stats["resend-cts"]}
			/>

			<StatBox
				title="Eftersända skapade"
				procentage={Number(100 * (stats["resend-created"] / stats.trips)).toFixed(2) + "%"}
				value={stats["resend-created"]}
			/>

			<StatBox
				title="Eftersända misslyckade"
				procentage={Number(100 * (stats["resend-failed"] / stats.trips)).toFixed(2) + "%"}
				value={stats["resend-failed"]}
			/>
			<StatBox
				title="Pengar besparade"
				procentage={""}
				value={
					new Intl.NumberFormat("sv-SE", { useGrouping: true }).format(
						(stats["resend-cts"] + stats["resend-created"]) * 1700
					) + "kr"
				}
			/>

			<p>
				Total:{" "}
				{stats["resend-failed"] +
					stats["resend-created"] +
					stats["resend-cts"] +
					stats.cancelled +
					stats["rerouted"] +
					stats["exception"]}
			</p>
		</div>
	);
};

const Trips = ({
	trips,
	isLoading,
	isError,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
}) => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	if (trips === undefined || trips?.length === 0) {
		return <p className="m-auto text-stone-500">Inga turer hittades</p>;
	}

	return (
		<div className="flex flex-1 w-full flex-col">
			{trips.map((trip: any, i: any) => (
				<TripRow key={"dr-" + i} trip={trip} />
			))}
		</div>
	);
};

export default function NotFinished() {
	const [month, setMonth] = usePersistentState("vassdupp-month", getYearMonthFormat());
	const [contract, setContract] = useState("ALL");
	const [page] = useState(1);
	const {
		data: trips,
		isLoading: isLoadingTrips,
		isError: isTripError,
	} = useFetch(`deviatedtrips/${contract}/${month}/${page}`);
	const {
		data: stats,
		isLoading: isLoadingStats,
		isError: isStatsError,
	} = useFetch(`deviatedstatsoverview/${contract}/${month}`);

	return (
		<div className="flex flex-1 px-6 flex-col">
			<div className="flex w-full justify-between items-center mb-6">
				<div>
					<h1 className="mt-12 font-semibold text-2xl">Ej slutförda turer</h1>
					{trips ? (
						<h1 className="font-semibold text-sm text-gray-700">
							{stats?.trips}st turer under period
						</h1>
					) : null}
					<p className="font-medium text-stone text-sm w-40 text-stone-500 mb-8 whitespace-nowrap">
						Alla turer som ej är slutförda i trafiken för en specifierad period
					</p>
				</div>
				<div className="flex">
					<MonthSelector value={month} onChange={setMonth} />
					<ContractSelector value={contract} onChange={setContract} />
				</div>
			</div>

			<TripStats stats={stats} isLoading={isLoadingStats} isError={isTripError} />

			<div className="flex flex-1 flex-col w-full">
				<TableHeader items={HEADER_NAMES} />
				<Trips trips={trips} isLoading={isLoadingTrips} isError={isStatsError} />
			</div>
		</div>
	);
}
