import { Link } from "wouter";
import { fetchInfoDisplys } from "../helper/network";
import Modal from "../components/modal";
import { useState } from "react";
import Input from "../components/input";
import postman from "../helper/postman";
import InputSelect from "../components/inputSelect";
import { INFO_DISPLAY_TYPES, TypeInput } from "./infoDisplay";
import CopyText from "../components/copyText";
import { navigate } from "wouter/use-browser-location";

export default function InfoDisplays() {
	const { data, mutate } = fetchInfoDisplys();
	const [showCreateDisplay, setShowCreateDisplay] = useState(false);

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [type, setType] = useState("");
	const [typeId, setTypeId] = useState("");
	const [orientation, setOrientation] = useState("");
	const [config, setConfig] = useState("");

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (!name || !orientation || !type) {
			alert("Fyll i alla fält");
			return;
		}

		if (type !== "empty" && typeId === "") {
			alert("Fyll i alla fält");
			return;
		}

		try {
			const res = await postman.post("/info/displays", {
				name,
				description,
				orientation,
				type,
				typeId,
				config,
			});

			mutate((data: any) => [...data, res.data], false);

			// Navigate to the new display.
			navigate("/infodisplays/" + res.data.Id);
		} catch (error: any) {
			// Check if network error or badrequest.
			if (error?.response?.status === 400) {
				alert("Något gick fel. Kontrollera att du har fyllt i alla fält korrekt");
			} else {
				alert("Något gick fel");
			}
		}
	};

	const handleSetType = (value: any) => {
		setTypeId("");
		setType(value);
	};

	return (
		<div className="flex flex-1 flex-col">
			<Modal show={showCreateDisplay} closeModal={() => setShowCreateDisplay(false)}>
				<form
					className="flex flex-col justify-betweem w-full h-full items-center py-12 px-20"
					onSubmit={handleSubmit}
				>
					<div className="flex flex-col items-center mb-6">
						<h1 className="font-medium text-xl">Skapa skärm</h1>
						<p className="mt-2 text-slate-500 text-sm dark:text-stone-500">
							Fyll i fälten nedan för att skapa en skärm
						</p>
					</div>
					<div className="mt-6 w-full">
						<Input placeholder="Namn" value={name} onChange={setName} />
					</div>
					<div className="mt-6 w-full">
						<Input
							placeholder="Beskrivning"
							value={description}
							onChange={setDescription}
						/>
					</div>

					<div className="mt-6 w-full">
						<InputSelect
							items={[
								["l", "Lodrät"],
								["v", "Vågrät"],
							]}
							onChange={setOrientation}
							placeholder="orientation"
							value={orientation}
							required
						/>
					</div>

					<div className="mt-6 w-full">
						<InputSelect
							items={INFO_DISPLAY_TYPES}
							onChange={handleSetType}
							placeholder="Typ"
							value={type}
						/>
						<div className="mt-6 w-full">
							<TypeInput
								type={type}
								typeId={typeId}
								setTypeId={setTypeId}
								setConfig={setConfig}
								config={config}
							/>
						</div>
					</div>

					<div className="flex flex-row ] mt-6 justify-center items-center">
						<input
							type="submit"
							className="bg-blue-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 ml-6 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
							value="Skapa skärm"
						/>
					</div>
				</form>
			</Modal>
			<h1 className="font-bold text-2xl mt-12 mb-3">Skärmar</h1>
			<div className="flex justify-end">
				<div
					className="bg-blue-200 self-start px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => setShowCreateDisplay(true)}
				>
					Lägg till skärm
				</div>
			</div>
			<div className="flex flex-1 flex-col justify-start items-center mt-6">
				{data ? (
					data?.map((display: any) => (
						<Link
							className="flex flex-row w-full justify-between items-center py-2 border-b border-gray-200 dark:border-stone-900 hover:bg-slate-100 dark:hover:bg-stone-900 px-4 cursor-pointer transition-all"
							key={"display-" + display.Id}
							to={"/infodisplays/" + display.Id}
						>
							<div className="flex flex-col">
								<CopyText value={display.Id} placeholder="id">
									<p className="text-sm text-slate-500">{display.Id}</p>
								</CopyText>
								<p className="font-semibold">{display.Name}</p>
								<p className="text-sm text-slate-500">{display.Description}</p>
								<p className="text-sm text-slate-500">{display.LastUpdatep}</p>
								<p className="text-sm text-slate-500">{display.LastUpdatedIp}</p>
								<p className="text-sm text-slate-500">{display.AddedBy}</p>
							</div>
						</Link>
					))
				) : (
					<p className="mt-10 text-slate-500 font-medium dark:text-stone-500">Inga skärmar tillagda</p>
				)}
			</div>
		</div>
	);
}
