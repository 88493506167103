import { useState } from "react";
import { fetchUsers } from "../helper/network";
import Modal from "../components/modal";
import Input from "../components/input";
import InputSelect from "../components/inputSelect";
import TableHeader from "../components/tableHeader";
import { prettyDate, prettyTime } from "../helper/date";
import usePersistentState from "../helper/usePersistentState";

const headers = [
	{ name: "Avgångstid", width: "w-32" },
	{ name: "Typ", width: "w-24" },
	{ name: "Antal bussar", width: "w-32" },
	{ name: "Stationer", width: "w-48" },
	{ name: "Kommentar", width: "flex-1" },
	{ name: "Status", width: "w-40" },
];

const replacementTripsData: any = [];

const Trips = ({
	trips,
	isLoading,
	isError,
	setSelectedOrder,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
	setSelectedOrder: any;
}) => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	if (trips === undefined || trips?.length === 0) {
		return <p className="m-auto text-stone-500">Inga hållplatser hittades</p>;
	}

	return trips.map((trip: any, i: number) => (
		<ReplacementTripRow key={"dr-" + i} trip={trip} onClick={setSelectedOrder} />
	));
};

const STATIONS = [
	{ name: "Danderyds sjukhus", lines: ["L27", "L28", "L29"] },
	{ name: "Mörby station", lines: ["L27", "L28", "L29"] },
	{ name: "Djursholms Ösby", lines: ["L27", "L28", "L29"] },
	{ name: "Roslags Näsby", lines: ["L27", "L28"] },
	{ name: "Tibble", lines: ["L27"] },
	{ name: "Ensta", lines: ["L27"] },
	{ name: "Visinge", lines: ["L27"] },
	{ name: "Täby kyrkby", lines: ["L27"] },
	{ name: "Kragstalund", lines: ["L27"] },
	{ name: "Bällsta", lines: ["L27"] },
	{ name: "Vallentuna", lines: ["L27"] },
	{ name: "Ormsta", lines: ["L27"] },
	{ name: "Molnby", lines: ["L27"] },
	{ name: "Lindholmen", lines: ["L27"] },
	{ name: "Frösunda", lines: ["L27"] },
	{ name: "Ekskogen", lines: ["L27"] },
	{ name: "Kårsta", lines: ["L27"] },
	{ name: "Bråvallavägen", lines: ["L28"] },
	{ name: "Djursholms Ekeby", lines: ["L28"] },
	{ name: "Enebyberg", lines: ["L28"] },
	{ name: "Täby centrum", lines: ["L28"] },
	{ name: "Galoppfältet", lines: ["L28"] },
	{ name: "Viggbyholm", lines: ["L28"] },
	{ name: "Hägernäs", lines: ["L28"] },
	{ name: "Arninge", lines: ["L28"] },
	{ name: "Rydbo", lines: ["L28"] },
	{ name: "Täljö", lines: ["L28"] },
	{ name: "Åkers Runö", lines: ["L28"] },
	{ name: "Åkersberga", lines: ["L28"] },
	{ name: "Tunagård", lines: ["L28"] },
	{ name: "Österskär", lines: ["L28"] },
	{ name: "Vendevägen", lines: ["L29"] },
	{ name: "Östberga", lines: ["L29"] },
	{ name: "Altorp", lines: ["L29"] },
	{ name: "Lahäll", lines: ["L29"] },
	{ name: "Näsby alle", lines: ["L29"] },
	{ name: "Näsbypark", lines: ["L29"] },
];

const LINES = ["L27", "L28", "L29"];

const reasonsData = ["Yttre", "Infra", "SLF", "TDT", "TDF"];

const ORDER_TYPES = ["Pendel", "Enkel"];

interface ReplacementTripType {
	orderTimestamp: string;
	reason: string;
	trainNumber: number;
	type: string;
	busesOrdered: number;
	scheduledDepartureTime: string;
	stations: string[];
	comment: string;
	vehicles: string[];
	eta: string;
	ata: string;
	atd: string;
	completed: boolean;
}

const filledVehicles = (vehicles: string[]) => {
	return vehicles.filter((vehicle) => vehicle !== "").length;
};

const createStatus = (trip: ReplacementTripType) => {
	let style = "";
	let status = "";

	if (trip.completed) {
		status =
			filledVehicles(trip.vehicles) === trip.busesOrdered ? "Slutförd" : "Delvis slutförd";
		style = "text-gray-800 bg-gray-200";
	} else if (filledVehicles(trip.vehicles) === 0) {
		status = "Inväntar tillsättning";
		style = "text-amber-800 bg-amber-200";
	} else if (trip.busesOrdered !== filledVehicles(trip.vehicles)) {
		status = "Pågår: " + filledVehicles(trip.vehicles) + "/" + trip.busesOrdered;
		style = "text-green-800 bg-green-200";
	} else {
		status = "Pågår";
		style = "text-green-800 bg-green-200";
	}

	return <p className={"font-semibold px-2 py-1 rounded text-xs " + style}>{status}</p>;
};

function ReplacementTripRow({ trip, onClick }: { trip: ReplacementTripType; onClick: any }) {
	const scheduledDepartureTime = new Date(trip.scheduledDepartureTime).getTime();

	return (
		<div
			className="flex flex-row w-full items-center px-4 py-2 border-b hover:bg-stone-50 ease-out transition-all"
			onClick={() => onClick(trip)}
		>
			<p className="font-semibold text-xs w-32">{prettyTime(scheduledDepartureTime)}</p>
			<p className="font-semibold text-xs w-24">{trip.type}</p>
			<p className="font-semibold text-xs w-32">{trip.busesOrdered}</p>
			<p className="font-semibold text-xs w-48">{trip.stations.join(", ")}</p>
			<p className="font-semibold text-xs flex-1">{trip.comment}</p>
			<div className="flex flex-row items-center w-40">{createStatus(trip)}</div>
		</div>
	);
}

export default function ReplacementTrips() {
	const { data, mutate, isLoading, isError } = fetchUsers();
	const [showModal, setModal] = useState(false);

	const [fakeData, setFakeData] = usePersistentState<any>("fakedata", replacementTripsData);

	const [selectedOrder, setSelectedOrder] = useState<any>(null);

	// States for all inputs
	const [reason, setReason] = useState("");
	const [trainNumber, setTrainNumber] = useState("");
	const [type, setType] = useState("");
	const [busesOrdered, setBusesOrdered] = useState("");
	const [scheduledDepartureDate, setScheduledDepartureDate] = useState(
		`${new Date().toISOString().split("T")[0]}`
	);
	const [scheduledDepartureTime, setScheduledDepartureTime] = useState(
		`${new Date().getHours()}:${new Date().getMinutes()}`
	);
	const [line, setLine] = useState("");
	const [stations, setStations] = useState<any>([]);
	const [comment, setComment] = useState("");

	// Handlers for form submission and cancel
	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		// Add logic to handle form submission

		// add to fakeData

		const date = new Date(scheduledDepartureDate);
		const [hours, minutes]: any = scheduledDepartureTime.split(":");

		const newTrip = {
			orderTimestamp: new Date().toISOString(),
			reason,
			trainNumber,
			type,
			busesOrdered,
			scheduledDepartureTime: new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				hours,
				minutes
			),
			line,
			stations,
			comment,
			vehicles: [...Array(parseInt(busesOrdered))].map(() => ""),
			completed: false,
		};

		console.log(newTrip);

		setModal(false);
		setFakeData([...fakeData, newTrip]);
	};

	const handleCancel = () => {
		// Reset all states
		setReason("");
		setTrainNumber("");
		setType("");
		setBusesOrdered("");
		setScheduledDepartureTime("");
		setLine("");
		setStations([]);
		setComment("");
		setModal(false);
	};

	const handleVehicleChange = (index: number, value: string) => {
		const newTrip = { ...selectedOrder };

		newTrip.vehicles[index] = value;

		const newFakeData = [...fakeData];

		const indexInFakeData = newFakeData.findIndex((trip) => trip === selectedOrder);

		newFakeData[indexInFakeData] = newTrip;

		setFakeData(newFakeData);
		setSelectedOrder(newTrip);
	};

	const stopOrderStatus = () => {
		const newTrip = { ...selectedOrder, completed: true };

		const index = fakeData.findIndex((trip: any) => trip === selectedOrder);

		const newFakeData = [...fakeData];

		newFakeData[index] = newTrip;

		setFakeData(newFakeData);
		setSelectedOrder(newTrip);
	};

	const removeOrder = () => {
		const newFakeData = fakeData.filter((trip: any) => trip !== selectedOrder);
		setFakeData(newFakeData);
		setSelectedOrder(null);
	};

	return (
		<div className="flex flex-1 w-full h-full flex-col">
			<Modal show={showModal} closeModal={() => setModal(false)}>
				<form
					className="flex flex-col justify-between w-full h-full items-center py-8 px-16 max-h-screen overflow-y-scroll"
					onSubmit={handleSubmit}
				>
					<div className="flex flex-col items-center mb-6">
						<h1 className="font-medium text-xl">Skapa ersättningstur</h1>
						<p className="mt-2 text-slate-500 text-sm">
							Fyll i fälten nedan för att skapa en ersättningstur
						</p>
					</div>
					<div className="relative select-none w-full">
						<InputSelect
							placeholder="Typ av beställning"
							value={type}
							onChange={setType}
							items={ORDER_TYPES.map((type) => [type, type])}
						/>
					</div>
					<div className="mt-3 w-full">
						<Input
							placeholder="Antal bussar"
							value={busesOrdered}
							onChange={setBusesOrdered}
						/>
					</div>
					<div className="relative select-none w-full mt-3">
						<InputSelect
							placeholder="Linje"
							value={line}
							onChange={setLine}
							items={LINES.map((line) => [line, line])}
						/>
					</div>
					{line !== "" ? (
						<div className="w-full">
							<div className="mt-3 w-full">
								<select
									className="border rounded w-full px-1 pt-2 min-h-80 text-sm"
									multiple
									value={stations}
									onChange={(e: any) =>
										setStations(
											Array.from(e.target.selectedOptions).map(
												(option: any) => option.value
											)
										)
									}
								>
									{STATIONS.filter((station) => station.lines.includes(line)).map(
										(station, i) => (
											<option key={station.name} value={station.name}>
												{station.name}
											</option>
										)
									)}
								</select>
							</div>
						</div>
					) : null}

					<div className="mt-3 w-full flex-row flex">
						<div className="flex flex-1 w-1/2 mr-1">
							<Input
								placeholder="Beställd avgångstid"
								value={scheduledDepartureDate}
								type="date"
								onChange={setScheduledDepartureDate}
							/>
						</div>
						<div className="flex flex-1 w-1/2 ml-1">
							<Input
								placeholder=""
								value={scheduledDepartureTime}
								type="time"
								onChange={setScheduledDepartureTime}
							/>
						</div>
					</div>

					<div className="mt-3 w-full">
						<Input placeholder="Tågnr" value={trainNumber} onChange={setTrainNumber} />
					</div>
					<div className="mt-3 w-full">
						<InputSelect
							value={reason}
							onChange={setReason}
							placeholder="Orsak"
							items={reasonsData.map((reason) => [reason, reason])}
						/>
					</div>
					<div className="mt-3 w-full">
						<Input placeholder="Kommentar" value={comment} onChange={setComment} />
					</div>

					<div className="flex flex-row mt-3 justify-center items-center">
						<div
							className="bg-slate-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:text-black"
							onClick={() => {
								handleCancel();
							}}
						>
							Avbryt
						</div>
						<input
							type="submit"
							className="bg-blue-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 ml-6 dark:text-black dark:bg-blue-400"
							value="Skapa beställning"
						/>
					</div>
				</form>
			</Modal>

			<Modal show={selectedOrder} closeModal={() => setSelectedOrder(null)}>
				{selectedOrder ? (
					<div className="flex flex-col max-w-[120rem] min-w-96 py-12 px-12">
						<div className="flex flex-row justify-between items-center">
							<p className="text-gray-700 font-semibold text-lg w-96">
								{selectedOrder.type} - {selectedOrder.busesOrdered} bussar
							</p>

							<div className="flex flex-row min-w-32 justify-right items-end">
								<div className="ml-auto">{createStatus(selectedOrder)}</div>
							</div>
						</div>
						<h1 className="font-semibold self-start rounded">
							{prettyTime(new Date(selectedOrder.scheduledDepartureTime).getTime())}{" "}
							{prettyDate(new Date(selectedOrder.scheduledDepartureTime).getTime())}
						</h1>
						<p>
							<span className="font-medium">{selectedOrder.line}: </span>{" "}
							{selectedOrder.stations.join(", ")}
						</p>
						{selectedOrder.comment ? (
							<p>
								<span className="">Kommentar: </span>{" "}
							</p>
						) : null}

						{!selectedOrder.completed ? (
							<div>
								<div className="flex flex-col mt-3 w-full">
									<p className="font-semibold text-sm">Fordon:</p>
									<div className="flex flex-col items-center mt-4">
										{selectedOrder.vehicles.map(
											(vehicle: string, i: number) => (
												<div className="w-full mb-2">
													<Input
														key={i}
														type="text"
														value={vehicle}
														onChange={(newValue: any) =>
															handleVehicleChange(i, newValue)
														}
														placeholder="Vagnsnummer"
													/>
												</div>
											)
										)}
									</div>
								</div>

								<button
									className="bg-red-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 mt-3 dark:text-black"
									onClick={() => stopOrderStatus()}
								>
									Avboka beställning
								</button>

								<button
									className="bg-blue-200 ml-4 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 mt-3 dark:text-black"
									onClick={() => stopOrderStatus()}
								>
									Avsluta beställning
								</button>

								{/* <button
									className="bg-red-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 mt-3 ml-4 dark:text-black"
									onClick={() => removeOrder()}
								>
									Ta tillbaka beställning
								</button> */}
							</div>
						) : (
							<div>
								Fordon:{" "}
								<div className="flex flex-row">
									{selectedOrder.vehicles.map((veh: any) => (
										<p className="bg-gray-100 px-1 py-0.5 rounded mr-1 font-medium">
											{veh}
										</p>
									))}
								</div>
							</div>
						)}
					</div>
				) : null}
			</Modal>

			<h1 className="font-bold text-2xl mt-12 mb-3">Ersättningstrafik</h1>
			<div className="flex justify-end mb-4">
				<div
					className={
						"bg-blue-200 self-start text-sm px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700 "
					}
					onClick={() => setModal(true)}
				>
					Skapa beställning
				</div>
			</div>
			<div className="flex flex-1 flex-col w-full">
				<TableHeader items={headers} />
				<Trips
					trips={fakeData}
					isLoading={isLoading}
					isError={isError}
					setSelectedOrder={setSelectedOrder}
				/>
			</div>
		</div>
	);
}
