import { useEffect } from "react";

import { checkIfUserIsLoggedIn } from "../helper/authentication";

import Navbar from "../components/navbar";

export default function Root({ children }: any) {
	useEffect(() => {
		checkIfUserIsLoggedIn();
	}, []);

	return (
		<div className="flex flex-1 flex-col md:flex-row w-full">
			<Navbar />

			<div className="py-4 max-w-[120rem] w-full mx-auto flex flex-1 flex-col box-border px-4">
				{children}
			</div>
		</div>
	);
}
