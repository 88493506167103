const MONTHS = [
	"Januari",
	"Februari",
	"Mars",
	"April",
	"Maj",
	"Juni",
	"Juli",
	"Augusti",
	"September",
	"October",
	"November",
	"December",
];

export const zeroed = (n: number) => (n < 10 ? `0${n}` : `${n}`);

export const prettyDate = (dateTime: number) => {
	const date = new Date(dateTime);

	return `${zeroed(date.getDate())}/${zeroed(date.getMonth() + 1)}`;
};

export const prettyFullDate = (dateTime: number) => {
	const date = new Date(dateTime);

	return `${zeroed(date.getFullYear())}-${zeroed(date.getMonth() + 1)}-${zeroed(date.getDate())}`;
};

export const prettyTime = (dateTime: number) => {
	const date = new Date(dateTime);

	return `${zeroed(date.getHours())}:${zeroed(date.getMinutes())}`;
};

export const firstOfMonthDate = (date: Date) => {
	date.setDate(1);
	date.setHours(0, 0, 0, 0);

	return date;
};

export const prettyMonthDate = (date: Date) => `${MONTHS[date.getMonth()]}`;

export const daysInMonth = (date: Date) => {
	return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const getLastMonday = (currentDate = new Date()) => {
	let day = currentDate.getDay();

	let diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);

	return new Date(currentDate.setDate(diff));
};

export function formatCTSDate(date: Date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
	const timezoneOffset = date.getTimezoneOffset();
	const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60))
		.toString()
		.padStart(2, "0");
	const timezoneOffsetMinutes = (timezoneOffset % 60).toString().padStart(2, "0");
	const timezoneSign = timezoneOffset >= 0 ? "-" : "+";

	const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}0000${timezoneSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`;
	return formattedDate;
}

export const getDaysToReport = (daysToReport: number) => {
	const startDate = new Date();
	const yesterday = new Date();

	startDate.setDate(startDate.getDate() - (daysToReport + 1));
	yesterday.setDate(yesterday.getDate() - 1);

	if (startDate.getTime() === yesterday.getTime()) {
		return "Igår";
	}

	return `${prettyDate(startDate.getTime())} - ${prettyDate(yesterday.getTime())}`;
};

export function getCurrentUTCDateTime(now: Date): string {
	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
	const day = String(now.getDate()).padStart(2, "0");
	const hours = String(now.getHours()).padStart(2, "0");
	const minutes = String(now.getMinutes()).padStart(2, "0");
	const seconds = String(now.getSeconds()).padStart(2, "0");

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
}

export const getYearMonthFormat = (): string => {
	const date = new Date();
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	return `${year}${month}`;
};

export const getTodayDateDashed = (): string => {
	const date = new Date();
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`;
};

export const caluclateDateString = (operatingDay: string, actualTime: string) => {
	const operatingDayTime = new Date(operatingDay + "T00:00:00");
	const [hours, minutes, seconds] = actualTime.split(":");

	operatingDayTime.setHours(0, 0, 0, 0);
	operatingDayTime.setHours(parseInt(hours), parseInt(minutes), parseInt(seconds));

	return operatingDayTime;
};


export const getDateHoursAndMinutes = (date: string): [number, number] => {
	const [_, time] = date.split("T");
	const [hours, minutes] = time.split(":");

	return [parseInt(hours), parseInt(minutes)];
}

export function prettyDateTime(dateString: string): string {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}