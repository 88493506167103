import { Link } from "wouter";
import MonthSelector from "../../components/monthSelector";
import TableHeader from "../../components/tableHeader";
import { getYearMonthFormat } from "../../helper/date";
import useFetch from "../../helper/swr";
import usePersistentState from "../../helper/usePersistentState";

const HEADER_NAMES_LINE = [
	{
		name: "Hållplatser",
		width: "w-52",
	},
	{
		name: "Datum",
		width: "w-40",
	},
	{
		name: "Linje",
		width: "w-40",
	},
	{
		name: "Tur",
		width: "w-40",
	},
	{
		name: "Utförd (%)",
		width: "flex-1",
	},
	{
		name: "Kommentar",
		width: "w-40",
	},
	{
		name: "Status",
		width: "w-40",
	},
];

const HEADER_NAMES_TRIP = [
	{
		name: "Hållplatser",
		width: "w-52",
	},
	{
		name: "Datum",
		width: "w-40",
	},
	{
		name: "Linje",
		width: "w-40",
	},
	{
		name: "Tur",
		width: "w-40",
	},
	{
		name: "Godkänd omläggning",
		width: "w-60",
	},
	{
		name: "Kommentar",
		width: "w-40",
	},
	{
		name: "Status",
		width: "w-40",
	},
];

const HEADER_NAMES_STOPS = [
	{
		name: "Hållplatser",
		width: "w-52",
	},
	{
		name: "Datum",
		width: "w-40",
	},
	{
		name: "Linje",
		width: "w-40",
	},
	{
		name: "Tur",
		width: "w-40",
	},
	{
		name: "Godkänd omläggning",
		width: "w-60",
	},
	{
		name: "Kommentar",
		width: "w-40",
	},
	{
		name: "Status",
		width: "w-40",
	},
];

const STATUS_COLOR_MAP: any = {
	"not-completed": "bg-red-100 text-red-600",
	"completed": "bg-green-100 text-green-600",
};

const STATUS_TITLE_MAP: any = {
	"not-completed": "Ej hanterad",
	"completed": "Hanterad",
};

const TripRowStatus = ({ status }: { status: string }) => {
	return (
		<div className="flex flex-row w-40">
			<p
				className={
					"font-medium whitespace-nowrap px-1.5 py-0.5 text-xs rounded " +
					STATUS_COLOR_MAP[status]
				}
			>
				{STATUS_TITLE_MAP[status]}
			</p>
		</div>
	);
};

function TripRow({ trip }: { trip: any }) {
	return (
		<Link
			to=""
			className="flex flex-row w-full items-center px-4 py-2 border-b hover:bg-stone-50 ease-out transition-all text-gray-600 text-sm"
		>
			<p className="w-52 whitespace-nowrap overflow-hidden text-ellipsis">
				{/* {trip.completed === 0 ? "Samtliga" : trip.stops.join(", ")} */}
			</p>
			<p className="w-40 whitespace-nowrap">{trip.date}</p>
			<p className="w-40 whitespace-nowrap overflow-hidden text-ellipsis">
				{trip.line ? trip.line : trip.lines.join(", ")}
			</p>
			<p className="w-40 whitespace-nowrap">{trip.trip}</p>
			<p className="flex-1">{trip.completed}%</p>
			<p className="w-60">{trip.approved}</p>
			<p className="w-40">{trip.comment}</p>
			<TripRowStatus status={trip.status} />
		</Link>
	);
}

const RenderTrends = ({
	trends,
	emptyName,
}: {
	trends: any;
	isLoading: boolean;
	isError: boolean;
	emptyName: string;
}) => {
	if (!trends || trends.length === 0) {
		return (
			<p className="text-xs mt-2 text-stone-500 font-medium">Inga återkommande {emptyName}</p>
		);
	}

	return (
		<div className="flex flex-col">
			{trends.map((trip: any, i: number) => (
				<TripRow trip={trip} key={"dr-" + i} />
			))}
		</div>
	);
};

export default function Trends() {
	const [month, setMonth] = usePersistentState("vassdupp-month", getYearMonthFormat());
	const { data: trends, isLoading: isLoading, isError: isError } = useFetch("trends/" + month);

	return (
		<div className="flex flex-1 px-6 flex-col">
			<div className="flex w-full justify-between items-center mb-6">
				<div>
					<h1 className="mt-12 font-semibold text-2xl">Trender</h1>

					<p className="font-medium text-stone text-sm w-40 text-stone-500 mb-12 whitespace-nowrap">
						Avvikelser som sker på samma plats tur eller linje och har inträffat minst
						tre gånger på en månad.
					</p>
				</div>
				<div className="flex">
					<MonthSelector value={month} onChange={setMonth} />
				</div>
			</div>
			<div className="flex flex-col w-full mb-12">
				<p className="font-bold text-sm mb-1 w-40 text-stone-700">Hållplatser</p>
				<TableHeader items={HEADER_NAMES_STOPS} />
				<RenderTrends
					trends={[]}
					isError={isError}
					isLoading={isLoading}
					emptyName="hållplatser"
				/>
			</div>
			<div className="flex flex-col w-full mb-12">
				<p className="font-bold text-sm mb-1 w-40 text-stone-700">Fordon</p>
				<TableHeader items={HEADER_NAMES_STOPS} />
				<RenderTrends
					trends={trends?.vehicles}
					isError={isError}
					isLoading={isLoading}
					emptyName="fordon"
				/>
			</div>
			<div className="flex flex-col w-full mb-12">
				<p className="font-bold text-sm mb-1 w-40 text-stone-700">Turer</p>
				<TableHeader items={HEADER_NAMES_TRIP} />
				<RenderTrends
					trends={trends?.trips}
					isError={isError}
					isLoading={isLoading}
					emptyName="turer"
				/>
			</div>
			<div className="flex flex-col w-full mb-12">
				<p className="font-bold text-sm mb-1 w-40 text-stone-700">Linje</p>
				<div className="flex flex-1 flex-col w-full">
					<TableHeader items={HEADER_NAMES_LINE} />
					<RenderTrends
						trends={trends?.lines}
						isError={isError}
						isLoading={isLoading}
						emptyName="linjer"
					/>
				</div>
			</div>
		</div>
	);
}
