import { Link } from "wouter";
import ContractSelector from "../../components/contractSelector";
import MonthSelector from "../../components/monthSelector";
import TableHeader from "../../components/tableHeader";
import { STATUS_COLOR_MAP, STATUS_TITLE_MAP } from "../../config";
import { getYearMonthFormat, prettyFullDate } from "../../helper/date";
import useFetch from "../../helper/swr";
import { TripException } from "../../types";
import { useMemo, useState } from "react";
import { StatBox } from "../../components/statbox";
import usePersistentState from "../../helper/usePersistentState";
import Modal from "../../components/modal";
import InputFile from "../../components/inputFile";
import { getWSJSON } from "../../helper/xlsx";
import postman from "../../helper/postman";
import toast from "react-hot-toast";

const HEADER_NAMES = [
	{ name: "Datum", width: "w-24" },
	{ name: "Linje", width: "w-20" },
	{ name: "Tur", width: "w-24" },
	{ name: "Hållplatser", width: "w-32" },
	{ name: "Fordon", width: "w-28" },
	{ name: "Kommentar", width: "flex-1" },
	{ name: "Status", width: "w-40" },
];

const checkIfExceptions = (trip: any): TripException[] => {
	return [];
};

const getStatusColor = (status: any, exceptions: string[]) => {
	if (exceptions.length !== 0) {
		return " bg-green-100 text-green-600";
	}

	return STATUS_COLOR_MAP[status];
};

export const TripStatus = ({
	status,
	exceptions,
}: {
	status: any;
	exceptions: TripException[];
}) => {
	return (
		<div className="flex flex-row w-40">
			<p
				className={
					"font-medium whitespace-nowrap px-2 py-0.5 text-xs rounded " +
					getStatusColor(status, exceptions)
				}
			>
				{STATUS_TITLE_MAP[status]}
			</p>
		</div>
	);
};

export function TripRow({
	trip,
}: {
	trip: {
		operatingDay: string;
		line: string;
		trip: string;
		deviatedStops: string[];
		status: any;
		vehicle: string;
		calls: string[];
		type: string;
		comment: string;
	};
}) {
	return (
		<Link
			to={`/resend/trips/${trip.operatingDay}/${trip.line}/${trip.trip}/h19`}
			className="flex flex-row w-full items-center px-4 py-2 hover:bg-stone-50 dark:hover:bg-stone-950 ease-out transition-all"
		>
			<p className="font-semibold text-sm w-24">
				{trip.operatingDay.substring(0, 4)}-{trip.operatingDay.substring(4, 6)}-
				{trip.operatingDay.substring(6, 8)}
			</p>
			<div className="flex w-20">
				<p
					className={
						"font-semibold text-sm px-1.5 rounded bg-slate-200 dark:bg-blue-950 dark:text-blue-400"
					}
				>
					{trip.line}
				</p>
			</div>
			<p className="font-semibold text-sm w-24">{trip.trip}</p>
			<p className="font-semibold text-sm w-32">
				{trip.deviatedStops.length}/{trip.calls.length}st{" "}
				<span className="text-gray-500 text-xs">
					({((trip.deviatedStops.length / trip.calls.length) * 100).toFixed(2)}%)
				</span>
			</p>
			<p className="w-28">{trip.vehicle ?? ""}</p>
			<p className="flex-1">{trip.comment}</p>
			<TripStatus status={trip.status} exceptions={checkIfExceptions(trip)} />
		</Link>
	);
}

const FILTER_MAP = {
	/* 	"cancelled": true, */
	/* 	"real-h19": true, */
	/* 	"false-h19": true, */
	/* 	"exception": true, */
	/* 	"close-h19": true, */
	/* 	"bad-resend": true, */
};

const Trips = ({
	trips,
	isLoading,
	isError,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
}) => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	if (trips === undefined || trips?.length === 0) {
		return <p className="m-auto text-stone-500">Inga turer hittades</p>;
	}

	const filteredTrips = trips.filter((trip: any) => FILTER_MAP[trip.status] === undefined);

	return (
		<div className="flex flex-1 w-full flex-col">
			{filteredTrips.map((trip, i) => (
				<TripRow key={"dr-" + i} trip={trip} />
			))}
		</div>
	);
};

const calculateStatuses = (trips: any) => {
	const TYPES_OF_STATUS_VALUES = {};
	const TYPES_OF_STATUS_KEYS = [];

	for (let i = 0; i < trips.length; i++) {
		const trip = trips[i];
		if (!TYPES_OF_STATUS_VALUES[trip.status]) {
			TYPES_OF_STATUS_VALUES[trip.status] = 1;
			TYPES_OF_STATUS_KEYS.push(trip.status);
		} else {
			TYPES_OF_STATUS_VALUES[trip.status]++;
		}
	}

	return [TYPES_OF_STATUS_KEYS, TYPES_OF_STATUS_VALUES];
};

const TripsHeader = ({
	trips,
	isLoading,
	isError,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
}) => {
	const heavyResult: any = useMemo(() => {
		if (isLoading || isError || trips === undefined || trips?.length === 0) {
			return [];
		}

		return calculateStatuses(trips);
	}, [trips, isLoading, isError]);

	if (heavyResult.length === 0) {
		return <p></p>;
	}

	return (
		<div className="flex flex-1 w-full flex-row mb-6">
			{heavyResult[0].map((status, i) => (
				<StatBox
					key={"stat-" + i}
					title={STATUS_TITLE_MAP[status]}
					procentage={((heavyResult[1][status] / trips.length) * 100).toFixed(2) + "%"}
					value={heavyResult[1][status]}
				/>
			))}
		</div>
	);
};

export default function H19Trips() {
	const [month, setMonth] = usePersistentState("vassdupp-month", getYearMonthFormat());
	const [contract, setContract] = useState("ALL");
	const [page] = useState(1);
	const [addStatusPop, setAddStatusPop] = useState(false);

	const {
		data: trips,
		isLoading: isLoadingTrips,
		isError: isTripError,
		mutate,
	} = useFetch(`h19s/${contract}/${month}/${page}`);

	const handleTripStatusUpload = (file: any) => {
		if (!file) {
			return;
		}

		try {
			setAddStatusPop(false);

			const reader = new FileReader();
			reader.onload = async (event: any) => {
				// Parse data
				const bstr = event.target.result;
				const rows = getWSJSON(bstr, 0, "H19");

				const TRIPS_WITH_NEW_STATUS = {};

				for (let i = 0; i < rows.length; i++) {
					const row = rows[i];

					TRIPS_WITH_NEW_STATUS[
						`${prettyFullDate(row.Trafikdatum).replaceAll(
							"-",
							""
						)}-${row.Linje.toString().substring(0, 3)}-${row.Tur}`
					] = row;
				}

				const TRIPS_TO_CHANGE = [];

				for (let i = 0; i < trips.length; i++) {
					const trip = trips[i];

					// Check if trip is waiting for.
					if (trip.status !== "waiting-decision") {
						continue;
					}

					// Check if trip is in the excel file.
					const row =
						TRIPS_WITH_NEW_STATUS[`${trip.operatingDay}-${trip.line}-${trip.trip}`];

					if (!row) {
						continue;
					}

					TRIPS_TO_CHANGE.push({
						trip: trip.trip,
						operatingDay: trip.operatingDay,
						line: trip.line,
						calls: trip.calls,
						vehicle: trip.vehicle,
						comment: row.Internkommentar,
						status: row.Orsak === "Falsk" ? "false-h19" : "real-h19",
					});
				}

				// Check if there is any trips to change.
				if (TRIPS_TO_CHANGE.length === 0) {
					toast.error("Inga turer att ändra");
					return;
				}

				toast.promise(postman.post("/h19s/tripswithoutdatafull", TRIPS_TO_CHANGE), {
					loading: "Lägger till turer...",
					success: "Turer har ändrats",
					error: "Ett fel uppstod",
				});

				const TRIPS_TO_CHANGE_KEYS = {};

				for (let i = 0; i < TRIPS_TO_CHANGE.length; i++) {
					const trip = TRIPS_TO_CHANGE[i];

					TRIPS_TO_CHANGE_KEYS[`${trip.operatingDay}-${trip.line}-${trip.trip}`] = trip;
				}

				const NEW_TRIPS = [];

				mutate((data: any) =>
					data.map((row) => {
						const key = `${row.operatingDay}-${row.line}-${row.trip}`;

						if (TRIPS_TO_CHANGE_KEYS[key]) {
							return {
								...row,
								status: TRIPS_TO_CHANGE_KEYS[key].status,
								comment: TRIPS_TO_CHANGE_KEYS[key].comment,
							};
						}

						return row;
					})
				);
			};
			reader.readAsBinaryString(file);
		} catch (error) {}
	};

	return (
		<>
			<Modal show={addStatusPop} closeModal={setAddStatusPop}>
				<div className="px-6 py-4 rounded">
					<h1 className="font-semibold">Ändra status på flera turer</h1>
					<p className="text-sm mb-5">
						Lägg till excel fil, viktigt att ha samma namn som i produktionsunderlag
					</p>
					<InputFile name="trips" accept=".xlsx" onHandleFile={handleTripStatusUpload} />
				</div>
			</Modal>
			<div className="flex flex-1 px-6 flex-col">
				<div className="flex w-full justify-between items-center">
					<div>
						<h1 className="mt-12 font-semibold text-2xl">H19 turer</h1>
						{trips ? (
							<h1 className="font-semibold text-sm text-gray-700">
								{trips.length}st turer under period
							</h1>
						) : null}
						<p className="font-medium text-stone text-sm w-40 text-stone-500 mb-8 whitespace-nowrap">
							Alla turer som är h19 i trafiken för en specifierad period
						</p>
					</div>
					<div className="flex flex-col">
						<div className="flex">
							<MonthSelector value={month} onChange={setMonth} />
							<ContractSelector value={contract} onChange={setContract} />
						</div>
						<p
							className="px-0 py-1 text-blue-600 font-medium cursor-pointer text-sm mt-4 underline text-right"
							onClick={() => setAddStatusPop(true)}
						>
							Ändra status på flera turer
						</p>
					</div>
				</div>

				<TripsHeader trips={trips} isLoading={isLoadingTrips} isError={isTripError} />

				<div className="flex flex-1 flex-col w-full">
					<TableHeader items={HEADER_NAMES} />
					<Trips trips={trips} isLoading={isLoadingTrips} isError={isTripError} />
				</div>
			</div>
		</>
	);
}
